import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import useStyles from "../styles";

export default function InputProfileMasked({
  value,
  name,
  type,
  label,
  onChange,
  widthSet,
  required,
  mask,
  ...props
}) {
  const classes = useStyles({ widthSet });
  return (
    <InputMask
      mask={mask}
      onChange={onChange}
      value={value}
    >
      {() => <TextField
        className={classes.inputForm}
        type={type}
        name={name}
        label={label}
        variant="outlined"
        required={required}
        InputLabelProps={{
          shrink: true,
        }}
        {...props}
      />}
    </InputMask>
  );
}

InputProfileMasked.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  widthSet: PropTypes.string,
  mask: PropTypes.string.isRequired,
};

InputProfileMasked.defaultProps = {
  type: "text",
  widthSet: "45%",
  required: true,
};
