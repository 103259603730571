import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  primaryGrid: {
    padding: "50px 50px 20px 80px",
    background: "rgba(0, 47, 73, 0.9)",
    color: "white",
    display: "flex",
    flexDirection: "row",
    "& ul": {
      padding: "15px 0 0 15px",
    },
    "& li": {
      lineHeight: "30px",
      fontSize: "14px",
    },
  },
  footerTitle: {
    "& img": {
      height: "40px",
      marginRight: "15px",
    },
    display: "flex",
    alignItems: "center",
    paddingBottom: "20px",
  },
  socialNetwork: {
    display: "flex",
    flex: "1",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingTop: "15px",
    "& img": {
      height: "40px",
      marginRight: "15px",
    },

  },
  copyRight: {
    color: "white",
    fontSize: "12px",
    textAlign: "center",

    backgroundColor: "#002F49",
    lineHeight: "30px",
    width: "100%",
  },
}));

export default useStyles;
