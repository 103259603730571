import { Typography } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useEffect, useState } from "react";
import OportunityCard from "../../../components/OportunityCard/OportunityCard";
import LateralCard from "../../../components/LateralCard";

import useStyle from "../styles";
import { getSelectionProcess } from "../../../common/api/selectionProcess/selectionProcess";

export default function Oportunities() {
  const classes = useStyle();
  const [internships, setInternships] = useState([]);
  const params = {
    order: "asc",
    size: 4,
    stage: "OPEN",
    sort: "registrationStart,desc",
  };

  const settings = async () => {
    const oportunities = await getSelectionProcess(params);
    setInternships(oportunities.content);
  };

  useEffect(() => {
    settings();
  }, []);

  return (
    <div className={classes.grid}>
      <Typography variant="h2">Oportunidades Recentes</Typography>
      <div className={classes.content}>
        <div className={classes.cards}>
          {internships?.map((internship) => (
            <OportunityCard internship={internship} key={internship.id} />
          ))}
        </div>
        <div>
          <LateralCard
            weight="body1"
            content="Eu consegui o 
            estágio dos meus sonhos através do Portal de Estágios! As oportunidades da plataforma são sempre muito boas."
            studentName="Joana Silva"
            studentLocal="Foz do Iguaçu, PR"
          />
          <LateralCard
            content="Faça a diferença com seu curriculo online!"
            endIcon={<ArrowForwardIcon />}
            goTo="/#"
            action
          />
        </div>
      </div>
    </div>
  );
}
