import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import useStyles from "./style";
import locationIcon from "../../assets/icons/location.svg";
import periodIcon from "../../assets/icons/period.svg";
import logoITAI from "../../assets/icons/logoItaiIcon.svg";
import { oportunityStage } from "../../common/enum/oportunityStage";
import { courseClassesPeriod } from '../../common/enum/courseClassesPeriod';


function OportunityCard(props) {
  const { internship } = props;
  const classes = useStyles();
  const [stage, setStage] = useState("");

  function settings() {
    if (internship.stage === "OPEN") {
      const alteredData = internship.registrationEnd
        .split("-")
        .reverse()
        .join("/");
      setStage(`Inscrições até ${alteredData}`);
    } else {
      Object.entries(oportunityStage).map(([key, value]) =>
        key === internship.stage ? setStage(value) : null
      );
    }
  }

  useEffect(() => {
    settings();
  }, []);

  return (
    <Card className={classes.root}>
      <div className={classes.section}>
        <CardMedia
          className={classes.media}
          image={
            internship.company.logoFilename
              ? internship.company.logoFilename
              : logoITAI
          }
          title={internship.title}
        />
        <CardContent className={classes.description}>
          <div className={classes.descriptionTitle}>
            <Typography gutterBottom component="h4" variant="h5">
              {internship.title}
            </Typography>
            <Typography gutterBottom component="h6" variant="h6">
              Vagas {internship.vacancies}
            </Typography>
            <Typography gutterBottom component="h6" variant="h6">
              {internship.salary === 0
                ? "Não remunerado"
                : `R$ ${parseFloat(internship.salary).toFixed(2)}`}
            </Typography>
          </div>
          <div className={classes.descriptionSubtitle}>
            <img src={locationIcon} alt="icone-location" />
            <Typography component="p" variant="body2">
              {internship.company.address.city}
            </Typography>
            <img src={periodIcon} alt="icone-period" />
            <Typography component="p" variant="body2">
              {internship.period && courseClassesPeriod[internship.period] || "Tempo Integral"}
            </Typography>
          </div>
        </CardContent>
      </div>
      <div className={classes.section2}>
        <CardContent className={classes.coursesAndDate}>
          <div className={classes.courses}>
            {internship.courses.map((course) => (
              <Typography
                component="p"
                variant="body1"
                className={classes.coursesItem}
                key={course.id}
              >
                {course.name}
              </Typography>
            ))}
          </div>
          <Typography component="p" variant="body2" className={classes.date}>
            {stage}
          </Typography>
        </CardContent>
        <CardActions>
          <Link
            className={classes.button}
            to={`/oportunidade/${internship.id}`}
          >
            Detalhes da Vaga
          </Link>
        </CardActions>
      </div>
    </Card>
  );
}

const Course = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

OportunityCard.propTypes = {
  internship: PropTypes.shape({
    id: PropTypes.number,
    stage: PropTypes.string,
    title: PropTypes.string,
    company: PropTypes.shape({
      businessName: PropTypes.string,
      cnpj: PropTypes.string,
      companyName: PropTypes.string,
      id: PropTypes.number,
      logoFilename: PropTypes.string,
      address: PropTypes.shape({
        city: PropTypes.string,
      }),
    }),
    period: PropTypes.string,
    vacancies: PropTypes.number,
    salary: PropTypes.number,
    courses: PropTypes.arrayOf(Course),
    registrationEnd: PropTypes.string,
  }).isRequired,
};

export default OportunityCard;
