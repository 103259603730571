import { Grid, Typography } from "@material-ui/core";
import useStyles from "./style";

// eslint-disable-next-line react/prop-types
function HeaderTitle({ title, image, subtitle }) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.headerTitle}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundImage: `url(${image})` }}
    >
      <Typography variant="h1" component="h1" align="center" className={classes.title}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="h4" component="h4" className={classes.subtitle}>
          <p>{subtitle}</p>
        </Typography>
      )}
    </Grid>
  );
}

export default HeaderTitle;
