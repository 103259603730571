export default {
  h1: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "3rem",
    lineHeight: "3.4rem",
    color: "white",
  },
  h2: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "2.5rem",
    lineHeight: "2.9rem",
    display: "flex",
    alignItems: "center",
  },
  h3: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "1.8rem",
    lineHeight: "2.2rem",
  },
  h4: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1.3rem",
    lineHeight: "1.5rem",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  h5: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "2rem",
    lineHeight: "2.4rem",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "white",
  },
  h6: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.5rem",
    lineHeight: "1.9rem",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "white",
  },
  body2: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
  },
  body1: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "1.1rem",
    lineHeight: "1.5rem",
  },
  button: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1.1rem",
    lineHeight: "1.5rem",
    textTransform: "capitalize",
  }
};
