import { useContext } from "react";
import { useForm } from "react-hook-form";
import { TextField, Typography, Button, CircularProgress } from "@material-ui/core";
import { Room, MailOutlineOutlined, Phone } from "@material-ui/icons";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import contatoTitle from "../../assets/images/contatoTitle.png";

import styles from "./styles";
import { sendContactForm } from "../../common/api/contact/contact";
import AppContext from "../../AppContext";

export default function Contact() {
  const classes = styles();
  const { showAlert } = useContext(AppContext);
  const { formState: { errors, isSubmitting }, handleSubmit, register, reset } = useForm();

  const submitForm = async (data) => {
    await sendContactForm(data)
      .then(() => {
        showAlert("success", "Mensagem enviada com sucesso!");
        reset();
      })
      .catch(() => {
        showAlert("error", "Erro ao enviar a mensagem!");
      });
  };

  return (
    <>
      <Header />
      <HeaderTitle title="Contatos" image={contatoTitle} />
      <section className={classes.container}>
        <section className={classes.contact}>
          <form className={classes.form} onSubmit={handleSubmit(submitForm)} autoComplete="off">
            <Typography variant="h6" component="h2" className={classes.title}>
              Deixe sua Mensagem
            </Typography>
            <TextField
              id="contact-name-id"
              label="Seu nome"
              name="name"
              variant="outlined"
              className={classes.textField}
              {...register("name", {
                required: "Preencha seu nome."
              })}
              error={!!errors?.name}
              helperText={errors?.name ? errors.name.message : null}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
            <TextField
              id="contact-email-id"
              label="E-mail"
              variant="outlined"
              name="email"
              className={classes.textField}
              {...register("email", {
                required: "Preencha seu email.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9._%-]+\.[A-Z]{2,}$/i,
                  message: "Insira um email válido."
                }
              })}
              error={!!errors?.email}
              helperText={errors?.email ? errors.email.message : null}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
            <TextField
              id="contact-message-id"
              label="Sua Mensagem"
              variant="outlined"
              className={classes.textField}
              multiline
              rows={4}
              name="message"
              {...register("message", {
                required: "Preencha sua messagem."
              })}
              error={!!errors?.message}
              helperText={errors?.message ? errors.message.message : null}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              type="submit"
              className={classes.button}
              disabled={isSubmitting}
            >
              Enviar
              {isSubmitting && <CircularProgress size={20} />}
            </Button>
          </form>
          <iframe
            className={classes.map}
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            title="mapa"
            src="https://maps.google.com/maps?hl=en&amp;q=ITAI - Instituto de Tecnologia Aplicada e Inovação, Av. Tancredo Neves, 6731 - Porto Belo, Foz do Iguaçu - PR, 85867-970&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          />
        </section>
        <section className={classes.footerContacts}>
          <div className={classes.footerContactsContent}>
              <Typography component="h4" variant="h4">
                <Room fontSize="large" /> Endereço
              </Typography>
            <Typography component="body2">
              Av. tancredo Neves, 6731. Foz do Iguaçu/PR. Brasil. CEP: 85867-970
            </Typography>
          </div>
          <div className={classes.footerContactsContent}>
            <Typography component="h4" variant="h4">
              <MailOutlineOutlined fontSize="large" />
              E-mail
            </Typography>
            <Typography component="body2">
              estagios@itai.org.br <br /> estagio@uniamerica.br
            </Typography>
          </div>
          <div className={classes.footerContactsContentLast}>
            <Typography component="h4" variant="h4">
              <Phone fontSize="large" />
              Telefone
            </Typography>
            <Typography component="body2">
              +55 45 3576-7113 <br /> +55 45 2105-9062
            </Typography>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
}
