import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  container: {
    padding: 30,
  },
  gridContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "& .MuiGrid-item": {
        padding: theme.spacing(1),
      }
    },
  },
  boxContacts: {
    width: "100%",
    border: "1px solid #EEF1F2",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    padding: "25px 30px",
  },
  boxContactsContent: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  contactTitle: {
    color: "#263238", 
  },
  contactItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: "20px 50px 0px 0px",
    "& svg": {
      color: "#002F49",
    },
    "& h4": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    }
  },
  boxSeparator: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: "30px 0",
    "& h4": {
      color: "#263238",
    },
  },
  form: {
    backgroundColor: "#EEF1F2",
    boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: 6,
    padding: "32px 49px",
  },
  title: {
    color: "#263238", 
    marginBottom: "15px",
  },
  textField: {
    backgroundColor: "white",
  },
  textFieldMultiline: {
    backgroundColor: "white",
  },
  button: {
    marginTop: "25px",
  },
  helperText: {
    fontSize: "15px",
  },
}));

export default styles;
