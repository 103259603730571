import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import authenticationUtils from "../../../common/security/token";

import useStyles from "../styles/Headerstyle";
import Modal from "../../Modal/Modal";

export default function LoginLogoutButton() {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const user = authenticationUtils.getUserActive();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleLogout = () => {
    authenticationUtils.removeToken();
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <>
      {authenticationUtils.isAuthenticated() ? (
        <>
          <Button
            aria-controls="dropdown"
            aria-haspopup="true"
            color="primary"
            variant="contained"
            onClick={handleClick}
            className={classes.logoutButton}
          >
            {user
              ? `Olá, ${user.entity.name.split(" ", 1)}!`
              : "Seja bem vindo!"}
          </Button>
          <Popper
            anchorEl={anchorEl}
            transition
            disablePortal
            open={Boolean(anchorEl)}
          >
            {() => (
              <Grow
                in={Boolean(anchorEl)}
                id="menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem key="drop-down-login" onClick={handleClose}>
                        <Link
                          key="drop-down-login-profile"
                          to="/perfil"
                        >
                          Meu Perfil <AccountCircleIcon />
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link
                          key="secondItem"
                          to="/"
                          onClick={handleLogout}
                        >
                          Sair <ExitToAppIcon />
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      ) : (
        <Button
          className={classes.button}
          onClick={handleModalOpen}
          color="primary"
          variant="contained"
          endIcon={<AccountCircleIcon />}
        >
          Login
        </Button>
      )}
      <Modal opened={modalOpen} handleModalClose={handleModalClose} activatedStep={1} />
    </>
  );
}
