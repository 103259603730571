import { get, post, put, remove } from "../config/api_methods";

export const getLanguages = () => {
  const response = get(`/languages`);
  return response;
};

export const getLanguageById = (languageId) => {
  const response = get(`/languages/${languageId}`);
  return response;
};

export const editLanguage = (languageId, params) => {
  const response = put(`/languages/${languageId}`, params);
  return response;
};

export const addLanguage = (params) => {
  const response = post(`/languages`, params);
  return response;
};

export const removeLanguage = (languageId) => {
  const response = remove(`/languages/${languageId}`);
  return response;
};
