import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ResumeForm from "./sections/ResumeForm";
import useStyles from "./styles";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";

export default function Resume() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.body}>
        <ProfileMenu />
        <ResumeForm />
      </div>
      <Footer />
    </div>
  );
}
