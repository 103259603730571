import { Button } from '@material-ui/core';
import { useEffect, useState, useContext } from 'react';

import AppContext from '../../../AppContext';
import abilityLevels from '../../../assets/data/AbilittyOptions';
import languageLevels from '../../../assets/data/languageOptions';
import { getLanguages } from '../../../common/api/languages/languages';
import { addStudentAbility, getStudentAbilities, removeStudenAbility } from '../../../common/api/student/abilities';
import { addLanguageToStudent, getStudentLanguages, removeLanguageFromStudent } from '../../../common/api/student/languages';
import authenticationUtils from '../../../common/security/token';
import AbilityCard from '../../../components/AbilityCard/AbilityCard';
import InputProfile from '../../../components/InputProfile/InputProfile';
import LanguageCard from '../../../components/LanguageCard/LanguageCard';
import SelectResume from '../../../components/SelectResume/SelectResume';
import useStyles from '../styles';
import ExperienceForm from './ExperienceForm';
import EnrollmentStepForm from './StepEnrollmentForm';

export default function ResumeForm() {
  const user = authenticationUtils.getUserActive();
  const classes = useStyles();
  const [values, setValues] = useState({
    languageLevel: "",
    ability: "",
    abilityLevel: "",
  });

  const { showAlert } = useContext(AppContext);
  const [student, setStudent] = useState({});
  const [languages, setLanguages] = useState([]);

  const settings = async () => {
    const { data } = await getLanguages();
    const studentLanguages = await getStudentLanguages();
    const studentAbilities = await getStudentAbilities();
    setLanguages(data.data.content);
    setStudent({
      languages: studentLanguages.data.data.content,
      abilities: studentAbilities.data.data.content,
    });
  };

  function verifyEmpty(val) {
    if (val === "" || val === undefined || val == null) {
      showAlert("error", "Todos os campos devem ser preenchidos!");
      return true;
    }
    return false;
  }
  const AddLanguage = async () => {
    await addLanguageToStudent({
      id: user.entity.id,
      language: values.language,
      level: values.languageLevel,
    });
    settings();
  };

  const RemoveLanguage = async (languageId) => {
    await removeLanguageFromStudent(languageId);
    settings();
  };

  const AddAbility = async () => {
    if (!verifyEmpty(values.ability)) {
      await addStudentAbility({
        description: values.ability,
        level: values.abilityLevel,
      });
      values.ability = "";
      settings();
    }
  };

  const RemoveAbility = async (abilityId) => {
    await removeStudenAbility(abilityId);
    settings();
  };

  useEffect(() => {
    settings();
  }, [user.entity.id]);

  const handleValues = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className={classes.form}>
      <h2>Habilidades</h2>
      <div className={classes.cardsBox}>
        {student.abilities?.map((ability) => (
          <AbilityCard
            key={ability.id}
            item={ability}
            onClick={() => RemoveAbility(ability.id)}
          />
        ))}
      </div>
      <div className={classes.inputsBox}>
        <InputProfile
          onChange={handleValues}
          name="ability"
          label="Adicione uma Habilidade"
          value={values.ability}
          widthSet="100%"
        />
        <SelectResume
          value={values.abilityLevel}
          id="ability-level-select"
          name="abilityLevel"
          label="Nível"
          options={abilityLevels}
          onChange={handleValues}
        />
        <Button
          className={classes.addButton}
          type="button"
          onClick={AddAbility}
        >
          +
        </Button>
      </div>
      <h2>Idiomas</h2>
      <div className={classes.cardsBox}>
        {student.languages?.map((language) => (
          <LanguageCard
            key={language.id}
            item={language}
            onClick={() => RemoveLanguage(language.id)}
          />
        ))}
      </div>
      <div className={classes.inputsBox}>
        <SelectResume
          value={values.language}
          id="language-select"
          name="language"
          label="Idioma"
          options={languages}
          onChange={handleValues}
        />
        <SelectResume
          value={values.languageLevel}
          id="language-level-select"
          name="languageLevel"
          label="Nível"
          options={languageLevels}
          onChange={handleValues}
        />
        <Button
          className={classes.addButton}
          type="button"
          onClick={AddLanguage}
        >
          +
        </Button>
      </div>
      <EnrollmentStepForm id={user.entity.id} />
      <ExperienceForm id={user.entity.id} />
    </div>
  );
}
