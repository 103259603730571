/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import validate from "validate.js";
import {
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Collapse,
  Link,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import LogoITAI from "../../assets/logos/logoHorizontalItai.svg";
import useStyles from "./SignUpStyle";
import PasswordTextField from "../../components/InputsMasks/Password/PasswordTextField";
import CpfTextField from "../../components/InputsMasks/CPF/CpfTextField";
import InputSignForm from "../../components/InputSignForm/InputSignForm";
import { createStudent } from "../../common/api/student/student";
import AppContext from "../../AppContext";
import signUpSchema from "../../assets/schemas/signUpSchema";
import { userInitialValue, userInitialAddress } from "../../assets/initialValues/userInitialValue";

export default function SignUp() {
  const { showAlert } = useContext(AppContext);
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Tente novamente mais tarde");

  const [formState, setFormState] = useState({
    isValid: false,
    values: userInitialValue,
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, signUpSchema, { fullMessages: false });

    setFormState((fState) => ({
      ...fState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((fState) => ({
      ...fState,
      values: {
        ...fState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...fState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const postAssociate = async (event) => {
    event.preventDefault();
    const body = {
      name: formState.values.name,
      cpf: formState.values.cpf.replace(/\D/g, ''),
      password: formState.values.password,
      email: formState.values.email,
      enabled: true,
      address: userInitialAddress,
      phone: "",
    };

    await createStudent(body)
      .then(() => {
        showAlert("success", "Cadastro realizado com sucesso!");
        history.push("/login");
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setErrorMessage("Usuário já cadastrado");
        }
        setOpen(true);
      });
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={classes.wrapper}>
      <NavLink to="/">
        <img src={LogoITAI} alt="logo Itai" />
      </NavLink>
      <Typography component="h1" variant="h5">
        PORTAL DE ESTÁGIOS
      </Typography>
      <Collapse in={open} className={classes.alert}>
        <Alert severity="warning">
          <AlertTitle>Não foi possivel realizar o cadastro</AlertTitle>
          {errorMessage}
        </Alert>
      </Collapse>
      <form className={classes.form} onSubmit={postAssociate}>
        <Typography component="h2" variant="h6">
          Cadastre-se
        </Typography>
        <InputSignForm
          name="name"
          label="Nome Completo"
          autoFocus
          value={formState.values.name}
          onChange={handleChange}
          error={hasError("name")}
          helperText={hasError("name") ? formState.errors.name[0] : null}
        />
        <InputSignForm
          label="E-mail"
          name="email"
          value={formState.values.email}
          onChange={handleChange}
          error={hasError("email")}
          helperText={hasError("email") ? formState.errors.email[0] : null}
        />
        <CpfTextField
          value={formState.values.cpf}
          handleChange={handleChange}
          error={hasError("cpf")}
          helperText={hasError("cpf") ? formState.errors.cpf[0] : null}
        />
        <PasswordTextField
          error={hasError("password")}
          helperText={
            hasError("password") ? formState.errors.password[0] : null
          }
          onChange={handleChange}
          value={formState.values.password}
        />
        <PasswordTextField
          error={hasError("confirmPassword")}
          helperText={hasError("confirmPassword") ? formState.errors.confirmPassword[0] : null}
          onChange={handleChange}
          value={formState.values.confirmPassword}
          name="confirmPassword"
          label="Confirmar Senha"
        />
        <Typography component="p" className={classes.privacyPolice}>
        Ao informar meus dados, eu concordo com a <Link to="/">Política de Privacidade.</Link>
        </Typography>
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Eu quero receber informações sobre vagas de estágio por e-mail"
          className={classes.checkbox}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.signUpButton}
        >
          Cadastrar
        </Button>
      </form>
      <div className={classes.loginBox}>
        Já possui uma conta?
        <br />
        <NavLink to="/login">Faça Login</NavLink>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
