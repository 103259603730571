/* eslint-disable import/prefer-default-export */
import moment from "moment";

export const enrollmentInitialValue = {
  businessName: "",
  cnpj: "",
  companyName: "",
  classesPeriod: "",
  duration: "",
  graduationSchedule: "",
  type: "",
  courseInfo: "",
  courseInfoName: "",
  institution: "",
  situation: "",
  graduationPeriod: 0,
  endDate: moment(),
  startDate: moment(),
}