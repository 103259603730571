import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  container: {
    padding: 40,
    display: "flex",
    gap: "50px",
    marginBottom: "50px",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  card: {
    border: "1px solid #CCC",
    borderRadius: "8px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxWidth: "350px",
    minWidth: "300px",
    height: "fit-content",

    "& img": {
      maxHeight: "25px",
      maxWidth: "25px",
      marginRight: "10px",
    },
    "& h3": {
      color: "#263238",
    }
  },
  cardItem: {
    display: "flex",
    flexDirection: "column",

    "& h4": {
      display: "flex",
      alignItems: "center",
      gap: "10px"
    },
    "& p": {
      marginTop: "10px",
      paddingLeft: "5px",
    },
  },

  progress: {
    width: "100%",
    position: "relative",
    top: "0",
    rigth: "0",
  },

  signal: {
    width: "15px",
    height: "15px",
    borderRadius: "50px",
    marginRight: "15px",
    boxShadow: "0 2px 2px rgba(0,0,0,0.2)",
  },

  description: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    maxWidth: "1200px",

    "& h5": {
      color: "black",
      display: "flex",
      alignItems: "center",
      textAlign: "left",
    },

    "& h4": {
      color: "black",
      borderBottom: "1px solid #CCCCCC",
      paddingBottom: "10px",
      marginTop: "20px",
    },

    "& p": {
      color: "#575756",
      padding: "10px 20px 10px 5px",
      textAlign: "left",
    },
  },
  tag: {
    fontSize: "13px",
    color: "#313131",
    fontWeight: "300",
    padding: " 0 25px 0 15px",
    background: "gold",
    marginLeft: "20px",
    position: "relative",

    "&:after": {
      content: "' '",
      position: "absolute",
      right: 0,
      top: 0,
      width: 0,
      height: 0,
      borderLeft: "20px solid transparent",
      borderTop: "18.5px solid white",
      borderBottom: "18.5px solid white",
    },
  },

  button: {
    borderRadius: "6px",
    color: "#002F49",
    marginTop: "20px",
    textAlign: "center",
    width: "300px",
  },
}));

export default styles;
