import { get, patch, post, put, remove } from "../config/api_methods";

export const getSelectionProcess = (params) =>
  new Promise((resolve) => {
    get(`/selection-processes`, params).then((response) => {
      const { data } = response.data;
      resolve(data);
    });
  });

export const getSelectionProcessById = (id) =>
  get(`/selection-processes/${id}`);

export const createSelectionProcess = (params) => {
  const response = post(`/selection-processes`, params);
  return response;
};

export const editSelectionProcess = (id) => {
  const response = put(`/selection-processes/${id}`);
  return response;
};

export const finishSelectionProcess = (id) => {
  const response = patch(`/selection-processes/${id}/finish`);
  return response;
};

export const reopenSelectionProcess = (id) => {
  const response = patch(`/selection-processes/${id}/reopenRegistrations`);
  return response;
};

export const statSelectionProcess = (id) => {
  const response = patch(`/selection-processes/${id}/startSelectionStage`);
  return response;
};

export const removeSelectionProcess = (id) => {
  const response = remove(`/selection-processes/${id}`);
  return response;
};
