/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { enrollmentInitialValue } from '../../../assets/initialValues/enrollmentInitialValue';
import { getCoursesFromInstitution } from '../../../common/api/educationalInstitution/courses';
import { getEdInstitutions } from '../../../common/api/educationalInstitution/educationalInstitution';
import { getStudentEnrollments, removeStudentEnrollment } from '../../../common/api/student/enrollments';
import EnrollmentCard from '../../../components/EnrollmentCard/EnrollmentCard';
import EnrollmentContext from '../context/EnrollmentContext';
import useStyles from '../styles';
import EnrollmentForm from './EnrollmentForm';
import NewCourseForm from './NewCourseForm';
import NewInstitutionAndCourseForm from './NewInstitutionAndCourseForm';
import NewInstitutionForm from './NewInstitutionForm';


function getStepContent(step) {
  switch (step) {
    case 0:
      return <EnrollmentForm />;
    case 1:
      return <NewInstitutionForm />;
    case 2:
      return <NewCourseForm  />;
    case 3:
      return <NewInstitutionAndCourseForm />;
    default:
      throw new Error("Request not found");
  }
}

export default function EnrollmentStepForm(props) {
  const { id } = props;

  const classes = useStyles();

  const [studentEnrollments, setStudentEnrollments] = useState([]);
  const [customInstitution, setCustomInstitution] = useState(false);
  const [customCourse, setCustomCourse] = useState(false);
  const [coursesOptions, setCoursesOptions] = useState([])
  const [institutionsOptions, setInstitutionsOptions] = useState([])
  const [enrollment, setEnrollment] = useState(enrollmentInitialValue);
  const [activeStep, setActiveStep] = useState(0);

  const handleStep = () => {
    if(!customInstitution && !customCourse){
      setActiveStep(0);
    } else if(customInstitution && !customCourse){
      setActiveStep(1);
    } else if(!customInstitution && customCourse){
      setActiveStep(2);
    } else if(customInstitution && customCourse){
      setActiveStep(3);
    }
  };

  const settings = async () => {
    const response = await getStudentEnrollments();
    const allInstitutions = await getEdInstitutions({ filter: 'ALL' });
    setStudentEnrollments(response.data.data.content);
    setInstitutionsOptions(allInstitutions.data.data.content);
  };

  const searchInstitutionCurses = async () => {
    const allCourses = await getCoursesFromInstitution(enrollment.institution.id);
    setCoursesOptions(allCourses.data.data.content);
  }

  const RemoveEnrollment = async (enrollmentId) => {
    await removeStudentEnrollment(enrollmentId);
    settings();
  };

  const handleValues = (event) => {
    const { name, value } = event.target;
    setEnrollment({
      ...enrollment,
      [name]: value,
    });
  };

  useEffect(() => {
    settings();
  }, [id]);

  useEffect(() => {
    handleStep();
  }, [customCourse, customInstitution]);

  return (
    <EnrollmentContext.Provider value={{ 
        settings,
        coursesOptions, 
        institutionsOptions,
        enrollment,
        setEnrollment, 
        searchInstitutionCurses,
        handleValues,
      }}
    >
        <h2>Formação Acadêmica</h2>

      <div className={classes.cardsBox}>
        {studentEnrollments?.map((exp) => (
          <EnrollmentCard
            key={exp.id}
            enrollment={exp}
            remove={() => RemoveEnrollment(exp.id)}
          />
        ))}
      </div>
      <div className={classes.eIandCourseButtons}>
        <Button
          className={classes.changeButton}
          type="button"
          onClick={() => setCustomInstitution(!customInstitution)}
        >
          {/* {customInstitution ? "Desejo selecionar instituições educacionais já cadastradas." : "Minha Instituição Educacional não está na lista."} */}
        </Button>
        <Button
          className={classes.changeButton}
          type="button"
          onClick={() => setCustomCourse(!customCourse)}
        >
          {/* {customCourse ? "Desejo selecionar cursos já cadastrados." : "Meu Curso não está na lista."} */}
        </Button>
      </div>
      {getStepContent(activeStep)}
    </EnrollmentContext.Provider>
  );
}

EnrollmentStepForm.propTypes = {
  id: PropTypes.number.isRequired,
};
