const partners = [
  {
    id: 1,
    title: "Itaipu",
    partnerImage: "/images/partners/itaipu.png",
  },
  {
    id: 2,
    title: "Parque Tecnológico de Itaipu - PTI",
    partnerImage: "/images/partners/pti.png",
  },
  {
    id: 3,
    title: "Uniamérica",
    partnerImage: "/images/partners/uniamerica.png",
  },
  {
    id: 4,
    title: "MicroRedes",
    partnerImage: "/images/partners/microrredes.png",
  },
  {
    id: 5,
    title: "Itaipu",
    partnerImage: "/images/partners/itaipu.png",
  },
  {
    id: 6,
    title: "Parque Tecnológico de Itaipu - PTI",
    partnerImage: "/images/partners/pti.png",
  },
  {
    id: 7,
    title: "Uniamérica",
    partnerImage: "/images/partners/uniamerica.png",
  },
  {
    id: 8,
    title: "MicroRedes",
    partnerImage: "/images/partners/microrredes.png",
  },
];

export default partners;
