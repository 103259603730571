export default {
  primary: {
    main: "#2B708B",
    dark: "#002F49",
  },
  secondary: {
    main: "#F7C601",
  },
  background: {
    default: "#FFFFFF",
  },
  text: {
    primary: "#263238",
    secondary: "#444",
  },
};
