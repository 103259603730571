const classesPeriodOptions = [
  {
    label: "Matutino",
    value: "MORNING",
  },
  {
    label: "Vespertino",
    value: "AFTERNOON",
  },
  {
    label: "Noturno",
    value: "EVENING",
  },
  {
    label: "Integral",
    value: "FULL_TIME",
  },
];

export default classesPeriodOptions;
