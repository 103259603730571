import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  container: {
    width: "fit-content",
  },
  section: {
    padding: "70px 0 0",
  },
  title: {
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  icon: {
    width: "17px",
    height: "17px",
    marginRight: "4px",
  },
  modal: {
    borderRadius: "8px",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    padding: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px",
    background: "linear-gradient(90deg, #002F49 50%, #FFF 50%)",
  },
  modalBody: {
    height: "550px",
    width: "600px",
    background: "linear-gradient(90deg, #002F49 50%, #FFF 50%)",
    padding: 0,
  },
  modalCloseButton: {
    color: "#999999",
    marginTop: "-12px",
    WebkitAppearance: "none",
    padding: "0",
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
    float: "right",
  },
  modalClose: {
    width: "16px",
    height: "16px",
  },
  label: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    transition: "0.3s ease all",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingLeft: "0",
    letterSpacing: "normal",
  },
  button: {
    margin: "8px",
    fontSize: "14px",
  },
  subLink: {
    fontSize: "16px",
    lineHeight: "21px",
    color: "#F7C601",
  },
  link: {
    fontSize: "18px",
    lineHeight: "26px",
    textAlign: "center",
    color: "#fff",
    "&:hover": {
      color: "#F7C601",
    },
  },
  steps: {
    marginTop: "20px",
    marginLeft: "50%",
    width: "300px",
  },
  wrapperLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    padding: "20px",
    minHeight: "300px",
    margin: "auto 0",
    justifyContent: "space-between",
  },
  socialNetwork: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "15px",
    "& img": {
      height: "40px",
      margin: "0 8px",
    },
  },
}));

export default useStyles;
