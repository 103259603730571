import { Grid, Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useState } from 'react';

import OportunitySearchInput from '../OportunitySearchInput/OportunitySearchInput';
import SignUpButton from '../SignUpButton/SignUpButton';
import useStyles from './style';

import authenticationUtils from "../../common/security/token";


export default function Banner() {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState({})

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
  }

  const onSubmit = (event) => {
    event.preventDefault();
    history.push({
      pathname:"/oportunidades",
      state
  })}

  return (
    <Grid
      className={classes.banner}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h1" component="h1" className={classes.title}>
        A Forma Mais Fácil de Encontrar seu Estágio
      </Typography>
      <OportunitySearchInput handleChange={handleChange} onSubmit={onSubmit} />
      {!authenticationUtils.isAuthenticated() && <SignUpButton />}
    </Grid>
  );
}
