import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  card: {
    display: "flex",
    gap: "16px",
    textAlign: "left",
    width: "91%",
    backgroundColor: "#fbfbfb",
    color: "#616161",
    padding: "16px",
    height: "fit-content",
    borderRadius: "4px",
    fontSize: "1.3rem",
    fontWeight: "400",
    justifyContent: "space-between",
  },

  buttons: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },

  button: {
    minWidth: "42px",
    padding: 0,

    "& .MuiSvgIcon-root": {
      width: "25px",
      height: "25px",
      color: "#515151",
    },
  },
}));

export default useStyles;
