import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router";
import Modal from "../Modal/Modal";

export default function Banner() {
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  
  const handleModalOpen = () => {
    if(isMobile){
      history.push("/cadastro")
    }else{
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleModalOpen}
        variant="contained"
        color="secondary"
        size="large"
      >
        CRIE SUA CONTA GRÁTIS
      </Button>
      <Modal opened={modalOpen} handleModalClose={handleModalClose} activatedStep={2} />
    </>
  );
}
