import authenticationUtils from '../../security/token';
import { get, post, put, remove } from '../config/api_methods';

export const getStudentExperiences = () => {
  const id = authenticationUtils.getId();
  const response = get(`/students/${id}/experiences`);
  return response;
};

export const addStudentExperience = (params) => {
  const id = authenticationUtils.getId();
  const response = post(`/students/${id}/experiences`, params);
  return response;
};

export const editStudentExperience = (experienceId, params) => {
  const id = authenticationUtils.getId();
  const response = put(`/students/${id}/experiences/${experienceId}`, params);
  return response;
};

export const removeStudentExperience = (experienceId) => {
  const id = authenticationUtils.getId();
  const response = remove(`/students/${id}/experiences/${experienceId}`);
  return response;
};
