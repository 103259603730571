/* eslint-disable no-unused-vars */
import { Button, Checkbox, Collapse, FormControlLabel, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import validate from 'validate.js';

import AppContext from '../../../AppContext';
import signInSchema from '../../../assets/schemas/signInSchema';
import { authUser } from '../../../common/api/users/user';
import authenticationUtils from '../../../common/security/token';
import CpfTextField from '../../InputsMasks/CPF/CpfTextField';
import PasswordTextField from '../../InputsMasks/Password/PasswordTextField';
import modalContext from '../context/ModalContext';
import useStyles from '../styles/ModalsContentStyle';

export default function SignIn() {
  const { handleBack, handleModalClose } = useContext(modalContext);
  const { showAlert } = useContext(AppContext);
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("CPF ou senha estão incorretos");

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      cpf: "",
      password: "",
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, signInSchema, { fullMessages: false });

    setFormState((fState) => ({
      ...fState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((fState) => ({
      ...fState,
      values: {
        ...fState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...fState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    const formStateValues = {
      username: formState.values.cpf.replace(/\D/g, ''),
      password: formState.values.password,
    };
    authUser(formStateValues)
      .then((response) => {
        authenticationUtils.setToken(response.data.token);
        handleModalClose();
        showAlert("success", "Login realizado com sucesso!");
        history.push("/");
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setErrorMessage("Usuário não cadastrado");
        }
        setOpen(true);
      });
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={classes.wrapper}>
      <Typography component="h1" variant="h5">
        Faça seu login
      </Typography>
      <Collapse in={open} className={classes.alert}>
        <Alert severity="warning">
          <AlertTitle>Não foi possivel realizar o Login</AlertTitle>
          {errorMessage}
        </Alert>
      </Collapse>
      <form className={classes.form} onSubmit={handleLogin}>
        <CpfTextField
          autoFocus
          value={formState.values.cpf}
          handleChange={handleChange}
          error={hasError("cpf")}
          helperText={hasError("cpf") ? formState.errors.cpf[0] : null}
        />
        <PasswordTextField
          error={hasError("password")}
          helperText={hasError("password") ? formState.errors.password[0] : null}
          onChange={handleChange}
          value={formState.values.password}
        />
        <Button
          type="button"
          onClick={handleBack}
          className={classes.forgotpasssword}
        >
          Esqueceu sua senha?
        </Button>
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Permanecer conectado"
          className={classes.checkbox}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.loginButton}
          disabled={!formState.isValid}
        >
          Entrar
        </Button>
      </form>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
