/* eslint-disable react/require-default-props */
import React from "react";
import { Link } from "@material-ui/core";
import facebookLogo from "../../../assets/icons/facebookIconCircle.svg";
import instagramLogo from "../../../assets/icons/instagramIconCircle.svg";
import linkedInLogo from "../../../assets/icons/linkedinIconCircle.svg";
import uniamericaLogo from "../../../assets/icons/UAIcon.svg";
import whatsappLogo from "../../../assets/icons/whatsappIconCircle.svg";

import useStyles from "../styles/Headerstyle";

export default function ModalLogoAndIcons() {
  const classes = useStyles();

  return (
    <div className={classes.socialNetwork}>
      <Link
        href="https://api.whatsapp.com/send?phone=554535767113"
        target="_blank"
      >
        <img src={whatsappLogo} alt="Whatsapp Logo" />
      </Link>
      <Link href="https://www.facebook.com/itai.org.br" target="_blank">
        <img src={facebookLogo} alt="Facebook Logo" />
      </Link>
      <Link href="https://www.instagram.com/itai.org.br/" target="_blank">
        <img src={instagramLogo} alt="Instagram Logo" />
      </Link>
      <Link href="https://www.linkedin.com/company/itai/" target="_blank">
        <img src={linkedInLogo} alt="Linkedin Logo" />
      </Link>
      <Link href="https://uniamerica.br/" target="_blank">
        <img src={uniamericaLogo} alt="Uniamerica Logo" />
      </Link>
    </div>
  );
}
