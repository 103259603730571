import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  textField: {
    marginTop: "15px",
    color: "#002F49", 
  },
  inputForm: {
    width: (props) => props.widthSet,
    margin: "0",
    color: "black",
    
    "& label": {
      color: "#002F49",
    },
    "& input": {
      backgroundColor: "white",
    },
  },
}));

export default useStyles;
