import { get, post, remove } from "../config/api_methods";
import authenticationUtils from "../../security/token";

export const getStudentLanguages = () => {
  const id = authenticationUtils.getId();
  const response = get(`/students/${id}/languages`);
  return response;
};

export const addLanguageToStudent = (params) => {
  const id = authenticationUtils.getId();
  const response = post(`/students/${id}/languages`, params);
  return response;
};

export const removeLanguageFromStudent = (languageId) => {
  const id = authenticationUtils.getId();
  const response = remove(`/students/${id}/languages/${languageId}`);
  return response;
};
