import JwtDecode from "jwt-decode";
import Cookies from "js-cookie";

const TOKEN_KEY = "portal-estagios-token";

const isAuthenticated = () => {
  if (
    Cookies.get(TOKEN_KEY) === null ||
    typeof Cookies.get(TOKEN_KEY) === "undefined"
  ) {
    return false;
  }
  return true;
};

const isExpired = () => {
  if (isAuthenticated()) {
    const { exp } = JwtDecode(Cookies.get(TOKEN_KEY));
    if (exp < (new Date().getTime() + 1) / 1000) {
      return true;
    }
  }
  return false;
};

const removeToken = () => {
  Cookies.remove(TOKEN_KEY);
};

const getToken = () => {
  if (!isExpired()) {
    return Cookies.get(TOKEN_KEY);
  }
  removeToken();
  return 0;
};

const getId = () => {
  if (isAuthenticated()) {
    const token = getToken();
    const decode = JwtDecode(token);
    const { entity } = decode;
    return entity.id;
  }
  return 0;
};
const getUserId = () => {
  if (isAuthenticated()) {
    const token = getToken();
    const decode = JwtDecode(token);
    const { id } = decode;
    return id;
  }
  return 0;
};

const setToken = (token) => {
  Cookies.set(TOKEN_KEY, token);
};

const getAuthorities = () => {
  if (isAuthenticated()) {
    const decoded = JwtDecode(getToken());
    return decoded.roles;
  }
  return 0;
};

const isAdmin = () => {
  const authorities = getAuthorities().filter(
    (role) => role.authority === "ADMIN"
  );
  return authorities.length > 0;
};

const getUserActive = () => {
  if (isAuthenticated()) {
    const decoded = JwtDecode(getToken());
    return decoded;
  }
  return false;
};

const authenticationUtils = {
  isAuthenticated,
  removeToken,
  getToken,
  setToken,
  getAuthorities,
  isAdmin,
  getUserActive,
  getId,
  getUserId,
};

export default authenticationUtils;
