import { makeStyles } from '@material-ui/core/styles';

const commomInputs = {
  background: "#FFFFFF",
  minWidth: "250px",
  "@media (max-width: 680px)": {
      marginBottom: "13px",
      borderRadius: "6px",
      width: "100%",
  }
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: "fit-content",
    marginTop: (props) => props.customMargin ? '-40px' : '24px',
    marginBottom: theme.spacing(3),
    background: "rgba(255, 255, 255, 0.35)",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: (props) => props.customMargin ? '0' : "25px 35px", 

    "@media (max-width: 680px)": {
      flexDirection: "column",
    }
  },
  input: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px 0px 0px 6px",
      ...commomInputs,
    },
  },

  input2: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      ...commomInputs,
    },
  },

  search: {
    width: "100px",
    maxWidth: "150px",
    background: "#002F49",
    height: "55.28px",
    borderRadius: "0px 6px 6px 0px",
    cursor: "pointer",

    "& svg": {
      color: 'white',
      width: '35px',
      height: '35px',
    },

    "@media (max-width: 680px)": {
      borderRadius: "6px",
      width: "100%",
      maxWidth: "500px",
    }
  },
}));

export default useStyles;
