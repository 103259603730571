const languageLeves = [
  {
    label: "Básico",
    value: "BASIC",
  },
  {
    label: "Intermediário",
    value: "INTERMEDIATE",
  },
  {
    label: "Avançado",
    value: "ADVANCED",
  },
  {
    label: "Fluente",
    value: "FLUENT",
  },
];

export default languageLeves;
