import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { withRouter } from "react-router";
import ModalForms from "./sections/ModalForm";
import useStyles from "./styles/ModalStyle";

const SignUpModal = (props) => {
  const classes = useStyles();
  const { opened, handleModalClose, activatedStep } = props;
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOpenModal(opened);
  }, [opened]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={openModal}
          keepMounted
          onClose={handleModalClose}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={handleModalClose}
            >
              <Close className={classes.modalClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <ModalForms handleModalClose={handleModalClose} activatedStep={activatedStep} />
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
};

SignUpModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  activatedStep: PropTypes.number.isRequired,
};

export default withRouter(SignUpModal);
