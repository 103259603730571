import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  inputForm: {
    width: (props) => props.widthSet,
    margin: "0",
    color: "black",

    "& label": {
      color: "#002F49",
    },
    "& input, .MuiOutlinedInput-multiline": {
      backgroundColor: "white",
    },

    "& .MuiOutlinedInput-inputAdornedEnd": {
      paddingRight: "10px",
    },
  },
}));

export default useStyles;
