import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

import styles from "../styles";

export default function CardItem(props) {
  const classes = styles();
  const { title, text, icon } = props;
  return (
    <div className={classes.cardItem}>
      <Typography component="h4" variant="body2">
        {icon && icon} {title}
      </Typography>
      <Typography component="p" variant="body1">{text}</Typography>
    </div>
  );
}

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  icon: PropTypes.string,
};
