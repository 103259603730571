import { get, post, remove } from "../config/api_methods";
import authenticationUtils from "../../security/token";

export const getStudentEnrollments = () => {
  const id = authenticationUtils.getId();
  const response = get(`/students/${id}/enrollments`);
  return response;
};

export const addStudentEnrollment = (params) => {
  const id = authenticationUtils.getId();
  const response = post(`/students/${id}/enrollments`, params);
  return response;
};

export const removeStudentEnrollment = (enrollmentId) => {
  const id = authenticationUtils.getId();
  const response = remove(`/students/${id}/enrollments/${enrollmentId}`);
  return response;
};
