const graduationScheduleOptions = [
  {
    label: "Anual",
    value: "ANNUAL",
  },
  {
    label: "Semestral",
    value: "SEMIANNUAL",
  },
  {
    label: "Quadrimestral",
    value: "QUARTERLY",
  },
];

export default graduationScheduleOptions;
