import { get, post, put, remove } from "../config/api_methods";

export const getEdInstitutions = (params) => {
  const response = get(`/educational-institutions`, params);
  return response;
};

export const getEdInstitutionById = (institutionId) => {
  const response = get(`/educational-institutions/${institutionId}`);
  return response;
};

export const editEdInstitution = (institutionId, params) => {
  const response = put(`/educational-institutions/${institutionId}`, params);
  return response;
};

export const createEdInstitution = (params) => {
  const response = post(`/educational-institutions/courses`, params);
  return response;
};

export const removeEdInstitution = (courseId) => {
  const response = remove(`/educational-institutions/${courseId}`);
  return response;
};
