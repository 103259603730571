import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    minHeight: "100vh",
    height: "100%",
    padding: "20px 20px 0 20px",
    color: "#002F49",

    "& h1": {
      color: '#002F49',
      fontWeight: "bolder",
      letterSpacing: "0.5px",
      marginBottom: "10px",
    },
    "& a": {
      display: "flex",
      justifyContent: "center",
    },
    "& img": {
      width: "70%",
    },
    "& .MuiAlert-root": {
      padding: "6px",
    },
  },
  alert: {
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "fit-content",
    marginTop: "8px",
    color: "#002F49",
    "& h2": {
      color: "#002F49",
    },
  },
  signUpButton: {
    height: "40px",
    backgroundColor: "#002F49",
    color: "#fff",
    borderRadius: "6px",
    marginTop: "15px",
  },
  privacyPolice: {
    padding: "10px 0 0 0px",
    "& a": {
      width: "fit-content",
      marginTop: "2px",
    }
  },
  checkbox: {
    "& svg": {
      width: "1.8rem",
      height: "1.8rem",
    },
    "& span": {
      color: "#002F49",
    }
  },
  textField: {
    marginTop: "15px",
  },
  loginBox: {
    color: "#002F49",
    cursor: "pointer",
    letterSpacing: "0.8px",
    fontWeight: "400",
    padding: "18px 0",
    textAlign: "center",
    borderTop: "1px solid #F7C601",
    borderBottom: "1px solid #F7C601",
    margin: "auto",

    "& a": {
      fontWeight: "500",
    }
  }
}));

export default useStyles;
