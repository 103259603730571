import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "fit-content",
    borderLeft: "10px solid #2B708B",
    borderRadius: "6px",
    boxShadow: "0 0 15 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#FAFAFA",
    padding: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "300px",
      height: "fit-content",
      maxWidth: "500px",
      margin: "0 auto 20px auto",
    },
  },
  section: {
    color: "black",
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    margin: 0,

    "&:hover": {
      backgroundColor: "#FAFAFA",
    },
  },
  section2: {
    color: "black",
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    margin: 0,

    "&:hover": {
      backgroundColor: "#FAFAFA",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  description: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "0",
    "&:last-child": {
      padding: "0",
    },
  },
  descriptionTitle: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "5px",

    "& h4": {
      textAlign: "left",
      flex: 3,
      fontFamily: "Roboto",
      color: "#263238",
      fontWeight: "500",
    },
    "& h6": {
      textAlign: "right",
      flex: 1,
      color: "#263238",
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  descriptionSubtitle: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    alignItems: "center",
    "& p": {
      color: "#263238",
      marginRight: "50px",
    },
    "& img": {
      height: 20,
      width: 20,
      marginRight: "10px",
    },
  },
  media: {
    height: 40,
    width: 45,
    margin: "0 20px 0 0",
  },
  coursesAndDate: {
    display: "flex",
    flexDirection: "column",
    flex: 4,
  },
  courses: {
    display: "flex",
  },
  coursesItem: {
    backgroundColor: "rgba(43, 112, 139, 0.5)",
    padding: "5px 8px",
    color: "white",
    borderRadius: "6px",
    fontWeight: 400,
    marginRight: "10px",
  },
  date: {
    fontFamily: "Lato",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "26px",
    alignItems: "center",
    color: "#2B708B",
    marginTop: "10px",
  },
  button: {
    backgroundColor: "#002F49",
    flex: 1,
    padding: "8px 20px",
    borderRadius: "6px",
    color: "white",
    fontSize: "15px",
    textTransform: "none",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",

    "&:hover": {
      backgroundColor: "#000D27",
    },
  },
}));

export default useStyles;
