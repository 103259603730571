import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { Typography, TextField, Button, Grid, CircularProgress } from "@material-ui/core";
import { MailOutlineOutlined, Phone } from "@material-ui/icons";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import contatoTitle from "../../assets/images/contatoTitle.png";

import styles from "./styles";
import { sendIEContactForm } from "../../common/api/contact/contact";
import AppContext from "../../AppContext";

export default function ContactInstitution() {
  const classes = styles();
  const { showAlert } = useContext(AppContext);
  const { formState: { errors, isSubmitting }, handleSubmit, register, control, reset } = useForm();

  const submitForm = async (data) => {

    await sendIEContactForm(data)
      .then(() => {
        showAlert("success", "Cadastro realizado com sucesso!");
        reset();
      })
      .catch(() => {
        showAlert("error", "Erro ao enviar o formulário!");
      });
  };


  return (
    <>
      <Header />
      <HeaderTitle title="Instituições de Ensino" image={contatoTitle} />
      <section className={classes.container}>
        <section className={classes.boxContacts}>
          <section className={classes.contactTitle}>
            <h4>Solicite um convênio conosco</h4>
          </section>
          <div className={classes.boxContactsContent}>
            <div className={classes.contactItems}>
              <Typography component="h4" variant="h4">
                <Phone fontSize="large" />
                Telefone e WhatsApp: 45 3576-7113
              </Typography>
            </div>
            <div className={classes.contactItems}>
              <Typography component="h4" variant="h4">
                <MailOutlineOutlined fontSize="large" />
                estagio@itai.org.br
              </Typography>
            </div>
          </div>
        </section>
        <section className={classes.boxSeparator}>
          <h4>Ou</h4>
        </section>
        <form className={classes.form} onSubmit={handleSubmit(submitForm)} autoComplete="off">
          <Typography variant="h6" component="h2" className={classes.title}>
            Faça seu cadastro prévio:
          </Typography>
          <Grid container spacing={4} className={classes.gridContainer}>
            <Grid item sm={12} md={6}>
              <TextField
                id="contactInstitution-name-id"
                label="Seu nome"
                variant="outlined"
                name="name"
                fullWidth
                className={classes.textField}
                {...register("name", {
                  required: "Preencha seu nome."
                })}
                error={!!errors?.name}
                helperText={errors?.name ? errors.name.message : null}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                id="contactInstitution-position-id"
                label="Cargo/Setor"
                variant="outlined"
                name="position"
                fullWidth
                className={classes.textField}
                {...register("position")}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                id="contactInstitution-institution-id"
                label="Instituição"
                variant="outlined"
                name="institution"
                fullWidth
                className={classes.textField}
                {...register("institution", {
                  required: "Preencha sua Instituição de Ensino."
                })}
                error={!!errors?.institution}
                helperText={errors?.institution ? errors.institution.message : null}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                id="contactInstitution-location-id"
                label="Cidade/Estado"
                variant="outlined"
                name="location"
                fullWidth
                className={classes.textField}
                {...register("location", {
                  required: "Preencha seu endereço."
                })}
                error={!!errors?.location}
                helperText={errors?.location ? errors.location.message : null}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="tel"
                fullWidth
                control={control}
                defaultValue=""
                rules={{
                  required: "Preencha o telefone."
                }}
                render={({ field: { onChange, value } }) => (
                  <InputMask mask="(99) 999999999" value={value} onChange={onChange}>
                    {inputProps =>
                      <TextField
                        id="contactBusiness-tel-id"
                        label="Telefone"
                        variant="outlined"
                        name="tel"
                        fullWidth
                        type="tel"
                        className={classes.textField}
                        error={!!errors?.tel}
                        helperText={errors?.tel ? errors.tel.message : null}
                        FormHelperTextProps={{
                          className: classes.helperText,
                        }}
                        {...inputProps}
                      />
                    }
                  </InputMask>)}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                id="contactInstitution-email-id"
                label="E-mail"
                variant="outlined"
                name="email"
                fullWidth
                type="email"
                className={classes.textField}
                {...register("email", {
                  required: "Preencha seu email.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9._%-]+\.[A-Z]{2,}$/i,
                    message: "Insira um email válido."
                  }
                })}
                error={!!errors?.email}
                helperText={errors?.email ? errors.email.message : null}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                id="outlined-basic"
                label="Sua mensagem"
                variant="outlined"
                multiline
                rows="6"
                fullWidth
                name="message"
                className={classes.textFieldMultiline}
                {...register("message")}
                />
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                type="submit"
                className={classes.button}
                disabled={isSubmitting}
                >
                Enviar
                {isSubmitting && <CircularProgress size={20} />}
              </Button>
            </Grid>
          </Grid>
        </form>
      </section>
      <Footer />
    </>
  );
}
