import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  container: {
    display: "flex",
    gap: "2rem",
    justifyItems: "initial",
    padding: "2rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    "& h3, img, p": {
        maxWidth: "500px",
        margin: "1rem"
    },
    "& p": {
        color: "#575756",
    },
    "@media (max-width: 800px)": {
      flexDirection: "column",
    }
  },
  separator: {
    border: "0",
    borderTop: "1px solid #002F49",
    marginLeft: "4rem",
    marginRight: "4rem",
    backgroundColor: "#575756"
  }
}));

export default styles;
