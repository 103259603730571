import { makeStyles } from "@material-ui/core/styles";
import QuotesLeft from "../../assets/icons/quotesLeft.png";
import QuotesRight from "../../assets/icons/quotesRight.png";
import LateralImage from "../../assets/images/lateralImage.png";

const useStyles = makeStyles(() => ({
  box: {
    width: "275px",
    margin: "auto 30px",
    background:
      "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FAFAFA",

    backgroundImage: `url(${QuotesLeft}), url(${QuotesRight})`,
    backgroundPosition: `left 10px top 10px, right 10px bottom 10px`,
    backgroundRepeat: "no-repeat",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "6px",
    padding: "35px",
    marginBottom: "20px",
  },
  boxStudent: {
    display: "flex",
    flexDirection: "column",
    "& h5": {
      marginTop: "20px",
      color: "#2B708B",
    },
    "& h6": {
      fontWeight: 400,
      color: "#575756",
    },
  },
  boxAction: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "275px",
    height: "290px",
    margin: "auto 30px",
    background: `url(${LateralImage}) no-repeat center`,
    gap: "20px",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "6px",
    padding: "35px 20px",
    marginBottom: "20px",
    "& h5": {
      fontWeight: "500",
    },
  },
}));

export default useStyles;
