import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from './styles';

export default function InputProfile({
  value,
  name,
  type,
  label,
  onChange,
  widthSet,
  required,
  ...props
}) {
  const classes = useStyles({ widthSet });
  return (
    <TextField
      type={type}
      name={name}
      label={label}
      variant="outlined"
      required={required}
      onChange={onChange}
      className={classes.inputForm}
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  );
}

InputProfile.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  widthSet: PropTypes.string,
};

InputProfile.defaultProps = {
  type: "text",
  widthSet: "45%",
  required: true,
};
