import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
// import EditIcon from "@material-ui/icons/Edit";
import { Button } from "@material-ui/core";
import useStyles from "./styles";

export default function ExperienceCard({ experience, remove }) {
  const classes = useStyles();
  const { company, occupation, startDate, endDate, description } = experience;

  return (
    <div className={classes.card}>
      <div>
        <h4>
          {occupation} - {company}
        </h4>
        <p>
          De {startDate} a {endDate}
        </p>
        <p>{description}</p>
      </div>
      <div className={classes.buttons}>
        {/* <Button className={classes.button} type="button" onClick={edit}>
          <EditIcon />
        </Button> */}
        <Button className={classes.button} type="button" onClick={remove}>
          <DeleteIcon />
        </Button>
      </div>
    </div>
  );
}

ExperienceCard.propTypes = {
  experience: PropTypes.shape({
    id: PropTypes.number.isRequired,
    company: PropTypes.string.isRequired,
    occupation: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  remove: PropTypes.func.isRequired,
};
