/* eslint-disable react/no-array-index-key */
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Modal from "../Modal/Modal";

import useStyles from "./style";

export default function DropDownButton(props) {
  const { buttonLabel, submenuOptions } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };


  return (
    <>
      <Button
        aria-controls="dropdown"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.button}
      >
        {buttonLabel}
      </Button>
      <Popper
        anchorEl={anchorEl}
        transition
        disablePortal
        open={Boolean(anchorEl)}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {submenuOptions.filter(option => !option.show).map((option, key) => (
                    <MenuItem key={`drop-down-${key}`} onClick={option.signUpModal ? handleModalOpen : handleClose}>
                      <Link
                        key={`drop-down-${option.name}-${key}`}
                        to={option.route || "#"}
                      >
                        {option.name}
                      </Link>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Modal opened={modalOpen} handleModalClose={handleModalClose} activatedStep={2} />
    </>
  );
}

DropDownButton.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  submenuOptions: PropTypes.arrayOf(PropTypes.shape({
    route: PropTypes.string,
    name: PropTypes.string.isRequired,
    signUpModal: PropTypes.bool,
  })),
};

DropDownButton.defaultProps = {
  submenuOptions: {
    signUpModal: false,
    route: null
  },
};

