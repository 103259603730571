import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Collapse,
  Link,
} from "@material-ui/core";
import { validate } from "validate.js";
import { Alert, AlertTitle } from "@material-ui/lab";
import useStyles from "../styles/ModalsContentStyle";
import { createStudent } from "../../../common/api/student/student";
import modalContext from "../context/ModalContext";
import signUpSchema from "../../../assets/schemas/signUpSchema";
import AppContext from "../../../AppContext";
import CpfTextField from "../../InputsMasks/CPF/CpfTextField";
import InputSignForm from "../../InputSignForm/InputSignForm";
import { userInitialValue, userInitialAddress } from "../../../assets/initialValues/userInitialValue";
import PasswordTextField from "../../InputsMasks/Password/PasswordTextField";

export default function SignUp() {
  const { handleBack } = useContext(modalContext);
  const { showAlert } = useContext(AppContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Tente novamente mais tarde");

  const [formState, setFormState] = useState({
    isValid: false,
    values: userInitialValue,
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, signUpSchema, { fullMessages: false });

    setFormState((fState) => ({
      ...fState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((fState) => ({
      ...fState,
      values: {
        ...fState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...fState.touched,
        [event.target.name]: true,
      },
    }));
  }

  const postAssociate = async (e) => {
    e.preventDefault();

    const body = {
      name: formState.values.name,
      cpf: formState.values.cpf.replace(/\D/g, ''),
      password: formState.values.password,
      email: formState.values.email,
      enabled: true,
      address: userInitialAddress,
      phone: "",
    };

    await createStudent(body)
      .then(() => {
        handleBack();
        showAlert("success", "Cadastro realizado com sucesso!");
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setErrorMessage("Usuário já cadastrado");
        }
        setOpen(true);
      });
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={classes.wrapper}>
      <Typography component="h1" variant="h5">
        Cadastre-se
      </Typography>
      <Collapse in={open} className={classes.alert}>
        <Alert severity="warning">
          <AlertTitle>Não foi possivel realizar o cadastro</AlertTitle>
          {errorMessage}
        </Alert>
      </Collapse>
      <form className={classes.form} onSubmit={postAssociate}>
        <Grid container>
          <Grid item xs={12}>
            <InputSignForm
              name="name"
              label="Nome Completo"
              autoFocus
              value={formState.values.name}
              onChange={handleChange}
              error={hasError("name")}
              helperText={hasError("name") ? formState.errors.name[0] : null}
            />
          </Grid>
          <Grid item xs={12}>
            <InputSignForm
              label="E-mail"
              name="email"
              value={formState.values.email}
              onChange={handleChange}
              error={hasError("email")}
              helperText={hasError("email") ? formState.errors.email[0] : null}
            />
          </Grid>
          <Grid item xs={12}>
            <CpfTextField
              value={formState.values.cpf}
              handleChange={handleChange}
              error={hasError("cpf")}
              helperText={hasError("cpf") ? formState.errors.cpf[0] : null}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              error={hasError("password")}
              helperText={
                hasError("password") ? formState.errors.password[0] : null
              }
              onChange={handleChange}
              value={formState.values.password}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              error={hasError("confirmPassword")}
              helperText={hasError("confirmPassword") ? formState.errors.confirmPassword[0] : null}
              onChange={handleChange}
              value={formState.values.confirmPassword}
              name="confirmPassword"
              label="Confirmar Senha"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" className={classes.privacyPolice}>
              Ao informar meus dados, eu concordo com a <Link to="/">Política de Privacidade.</Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="Eu quero receber informações sobre vagas de estágio por e-mail."
              className={classes.checkbox}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.loginButton}
        >
          Cadastrar
        </Button>
      </form>
    </div>
  );
}
