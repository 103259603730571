import { makeStyles } from '@material-ui/core/styles';

import Image from '../../assets/images/bannerImage.png';

const useStyles = makeStyles(() => ({
  banner: {
    width: "100%",
    height: "492px",
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",

    "@media (max-width: 680px)": {
      height: "fit-content",
    }
  },

  title: {
    textAlign: "center",
    padding: "10px",
  }
}));

export default useStyles;
