/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import validate from "validate.js";
import {
  Button,
  TextField,
  Typography,
  Collapse,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import LogoITAI from "../../assets/logos/logoHorizontalItai.svg";
import useStyles from "./ForgotPasswordStyle";
import authenticationUtils from "../../common/security/token";
import api from "../../common/api/config/api";
import AppContext from "../../AppContext";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "obrigatório" },
  },
};

export default function ForgotPassword() {
  const { showAlert } = useContext(AppContext);
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((fState) => ({
      ...fState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((fState) => ({
      ...fState,
      values: {
        ...fState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...fState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    api.post("/password-recovery", { email: formState.values.email })
      .then(() => {
        showAlert("success", "Email Enviado!");
        history.push("/login");
      })
      .catch((err) => new Error(setOpen(true), err));
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={classes.wrapper}>
      <NavLink to="/">
        <img src={LogoITAI} alt="logo Itai" />
      </NavLink>
      <Typography component="h1" variant="h5">
        PORTAL DE ESTÁGIOS
      </Typography>
      <Collapse in={open} className={classes.alert}>
        <Alert severity="warning">
          <AlertTitle>Usuário não cadastrado</AlertTitle>
          Verifique se o e-mail está correto
        </Alert>
      </Collapse>
      <form className={classes.form} onSubmit={handleLogin}>
        <Typography component="h2" variant="h6">
          Esqueceu sua senha?
        </Typography>
        <TextField
          variant="outlined"
          label="E-mail"
          name="email"
          autoComplete="email"
          required
          fullWidth
          autoFocus
          className={classes.textField}
          error={hasError("email")}
          helperText={hasError("email") ? formState.errors.email[0] : null}
          onChange={handleChange}
          value={formState.values.email || ""}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.button}
        >
          Recuperar Senha
        </Button>
      </form>
      <div className={classes.returnLoginBox}>
        <NavLink to="/login">Retornar para Login</NavLink>
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
