const situationOptions = [
  {
    label: "Cursando",
    value: "ENROLLED",
  },
  {
    label: "Trancado",
    value: "LOCKED",
  },
  {
    label: "Concluido",
    value: "COMPLETED",
  },
];

export default situationOptions;
