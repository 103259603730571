import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import useStyles from "./styles";

export default function InputSignForm({
  value,
  name,
  type,
  label,
  onChange,
  error,
  helperText,
  ...props
}) {
  const classes = useStyles();
  return (
    <TextField
      type={type}
      name={name}
      label={label}
      variant="outlined"
      required
      fullWidth
      onChange={onChange}
      className={classes.textField}
      value={value}
      error={error}
      helperText={helperText}
      {...props}
    />
  );
}

InputSignForm.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.string.isRequired,
};

InputSignForm.defaultProps = {
  type: "text",
};
