import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from './styles';

export default function SelectResume({
  value,
  id,
  name,
  label,
  options,
  onChange,
  labelOption,
  helperText,
  ...props
}) {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.inputForm}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        onChange={onChange}
        name={name}
        label={label}
        defaultValue=""
        value={value}
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.id || option} value={option.value || option} className={classes.option}>
            {option.label || option[labelOption] || option}
          </MenuItem>
        ))}
        {!options.length && <MenuItem value="" />}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

SelectResume.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  labelOption: PropTypes.string,
  helperText: PropTypes.string,
};

SelectResume.defaultProps = {
  labelOption: "name",
  helperText: '',
};
