import { useEffect, useState } from "react";
import { useParams } from "react-router";
import LinearProgress from "@material-ui/core/LinearProgress";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import oportunityTitle from "../../assets/images/faqTitle.png";

import styles from "./styles";
import Card from "./sections/Card";
import Description from "./sections/Description";
import OportunityContext from "./context/OportunityContext";
import { getSelectionProcessById } from "../../common/api/selectionProcess/selectionProcess";

export default function Oportunity() {
  const classes = styles();
  const params = useParams();
  const [internship, setInternship] = useState();
  const [loading, setLoading] = useState(false);

  const settings = async (id) => {
    const selectedInternship = await getSelectionProcessById(id);
    setInternship(selectedInternship.data.data);
    // eslint-disable-next-line no-console
    setLoading(true);
  };

  useEffect(() => {
    settings(params.id);
  }, [params.id]);

  return (
    <OportunityContext.Provider value={{ internship }}>
      <Header />
      <HeaderTitle title="Detalhes da Vaga" image={oportunityTitle} />
      {loading ? (
        <section className={classes.container}>
          <Card />
          <Description />
        </section>
      ) : (
        <LinearProgress className={classes.progress} />
      )}
      <Footer />
    </OportunityContext.Provider>
  );
}
