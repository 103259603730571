import { get, post, remove } from "../config/api_methods";

export const getCoursesFromInstitution = (instituitionId) => {
  const response = get(`/educational-institutions/${instituitionId}/courses`);
  return response;
};

export const addCourseToInstitution = (instituitionId, params) => {
  const response = post(
    `/educational-institutions/${instituitionId}/courses`,
    params
  );
  return response;
};

export const removeCourseFromInstitution = (instituitionId, couseId) => {
  const response = remove(
    `/educational-institutions/${instituitionId}/courses/${couseId}`
  );
  return response;
};
