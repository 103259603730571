const period = [
  {
    id: 1,
    name: "Matutino",
    value: "MORNING",
  },
  {
    id: 2,
    name: "Vespertino",
    value: "AFTERNOON",
  },
  {
    id: 3,
    name: "Noturno",
    value: "EVENING",
  },
  {
    id: 4,
    name: "Integral",
    value: "FULL_TIME",
  },
];

export default period;
