export const userInitialAddress = {
  street: "",
  neighborhood: "",
  number: "",
  complement: "",
  city: "",
  state: "",
  cep: "",
};

export const userInitialValue = {
  name: "",
  cpf: "",
  password: "",
  confirmPassword: "",
  email: "",
  enabled: true,
  address: userInitialAddress,
  phone: "",
};