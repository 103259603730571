import { get, post, put, remove } from "../config/api_methods";
import authenticationUtils from "../../security/token";

export const getStudentAbilities = () => {
  const id = authenticationUtils.getId();
  const response = get(`/students/${id}/abilities`);
  return response;
};

export const addStudentAbility = (params) => {
  const id = authenticationUtils.getId();
  const response = post(`/students/${id}/abilities`, params);
  return response;
};

export const editStudenAbility = (abilitieId) => {
  const id = authenticationUtils.getId();
  const response = put(`/students/${id}/abilities/${abilitieId}`);
  return response;
};

export const removeStudenAbility = (abilitieId) => {
  const id = authenticationUtils.getId();
  const response = remove(`/students/${id}/abilities/${abilitieId}`);
  return response;
};
