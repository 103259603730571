/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import useStyles from "../styles/ModalStyle";
import ModalLogoAndIcons from "./ModalLogoAndIcons";
import ModalContext from "../context/ModalContext";

function getStepContent(step) {
  switch (step) {
    case 0:
      return <ForgotPassword />;
    case 1:
      return <SignIn />;
    case 2:
      return <SignUp />;
    default:
      throw new Error("Request not found");
  }
}

export default function ModalForms(props) {
  const { handleModalClose, activatedStep } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(activatedStep);
  const wrapperRef = useRef(null);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleModalClose();
        setTimeout(setActiveStep(activatedStep), 5000);
      }
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }

  useOutsideAlerter(wrapperRef);
  return (
    <ModalContext.Provider value={{ handleNext, handleBack, handleModalClose }}>
      <div ref={wrapperRef} style={{ height: "500px" }}>
        <>
          {activeStep === 0 && (
            <div className={classes.modalContainer}>
              <ModalLogoAndIcons>
                <Link to="#" onClick={handleNext} className={classes.link}>
                  Retornar para Login
                </Link>
              </ModalLogoAndIcons>
              {getStepContent(activeStep)}
            </div>
          )}

          {activeStep === 1 && (
            <div className={classes.modalContainer}>
              <ModalLogoAndIcons>
                <Link to="#" onClick={handleNext} className={classes.link}>
                  Ainda não tem cadastro?
                  <br />
                  Cadastre-se
                </Link>
              </ModalLogoAndIcons>
              {getStepContent(activeStep)}
            </div>
          )}

          {activeStep === 2 && (
            <div className={classes.modalContainer}>
              <ModalLogoAndIcons>
                <Link to="#" onClick={handleBack} className={classes.subLink}>
                  Já possui uma conta? Faça login
                </Link>
              </ModalLogoAndIcons>
              {getStepContent(activeStep)}
            </div>
          )}
        </>
      </div>
    </ModalContext.Provider>
  );
}

ModalForms.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  activatedStep: PropTypes.number.isRequired,
};
