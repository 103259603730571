import { post, patch } from "../config/api_methods";

export const createUser = (params) => {
  const response = post("/users", params);
  return response;
};

export const authUser = async (credentials) => {
  const response = await post("/login", credentials);
  return response;
};

export const activePerson = (params) => {
  const response = patch("/users/person", params);
  return response;
};
