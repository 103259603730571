const signInSchema = {
  cpf: {
    presence: { allowEmpty: false, message: "obrigatório" },
    length: {
      is: 14,
    },
    format: {
      pattern: "^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}",
      message: "O CPF deve conter 11 dígitos",
    }
  },
  password: {
    presence: { allowEmpty: false, message: "obrigatório" },
    length: {
      maximum: 25,
      minimum: 5,
      tooShort: "A senha precisa ter no mínimo 5 caracteres",
      tooLong: "A senha pode ter no máximo 25 caracteres",
    },
  },
};

export default signInSchema;