import { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import useStyles from "./styles";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";
import { getRegistrations } from "../../common/api/student/registrations";
import OportunityCard from "../../components/OportunityCard/OportunityCard";

export default function MyProcess() {
  const classes = useStyles();
  const [process, setProcess] = useState([]);

  const settings = async () => {
    const { data } = await getRegistrations();
    setProcess(data.data.content);
  };

  useEffect(() => {
    settings();
  }, []);

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.body}>
        <ProfileMenu />
        <div className={classes.oportunities}>
          {process?.map((selectionProcess) => (
            <OportunityCard
              internship={selectionProcess.selectionProcess}
              key={selectionProcess.selectionProcess.id}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
