import faqTitle from "../../assets/images/faqTitle.png";
import ImgCandidatos from "../../assets/images/s2.jpeg";
import ImgFacilidade from "../../assets/images/s1.jpeg";
import ImgDocumentacao from "../../assets/images/sb2.jpeg";
import ImgSuporte from "../../assets/images/sb.jpeg";

import TextPictureContent from "../../components/TextPictureContent/TextPictureContent";

function Services() {

  const services = [
    {
      title: "Candidatos",
      image: ImgCandidatos,
      description: "Os melhores candidatos já pré-selecionados!",
    },
    {
      title: "Facilidade nos processos",
      image: ImgFacilidade,
      description: "Processos digitais e atendimento personalizado!",
    },
    {
      title: "Documentação",
      image: ImgDocumentacao,
      description: "Preparação de documentos e gerenciamento das contratações!",
    },
    {
      title: "Suporte",
      image: ImgSuporte,
      description: "Suporte aos orientadores e estagiários atendendo a Lei 11.788/2008!",
    }
  ];

  return (
    <TextPictureContent title="Nossos Serviços" image={faqTitle} elements={services} />
  );
}

export default Services;
