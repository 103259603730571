import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  headerTitle: {
    width: "100%",
    minHeight: "264px",
    height: "fit-content",
  },
  title: {
    "& h1": {
      padding: "20px",
    },
  },
  subtitle: {
    "& p": {
      fontFamily: "Rubik",
      fontSize: "2rem",
      lineHeight: "3rem",
      color: "#fff",
      fontWeight: "500",
      width: "58%",
      textAlign: "center",
      margin: "25px auto",
    },
  },
}));

export default useStyles;
