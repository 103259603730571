const faq = [
  {
    id: 1,
    question: "O que é o estágio?",
    answer:
      "Estágio é definido como o ato educativo escolar supervisionado, desenvolvido no ambiente de trabalho, que visa à preparação para o trabalho produtivo do estudante. O estágio integra o itinerário formativo do aluno e faz parte do projeto pedagógico do curso.",
  },
  {
    id: 2,
    question: "Quem pode contratar estagiário?",
    answer:
      "As pessoas jurídicas de direito privado e os órgãos da administração pública direta, autárquica e fundacional de qualquer dos poderes da união, dos estados, do Distrito Federal e dos municípios. Também os profissionais liberais de nível superior, devidamente registrados em seus respectivos conselhos, podem oferecer estágio.",
  },
  {
    id: 3,
    question: "Quem pode ser estagiário?",
    answer:
      "Estudantes que estiverem frequentando o ensino regular, em instituições de educação superior, de educação profissional, de ensino médio, da educação especial e dos anos finais do ensino fundamental, na modalidade profissional da educação de jovens e adultos.",
  },
  {
    id: 4,
    question: "Qual a diferença entre estagiário e jovem aprendiz?",
    answer:
      "Fundamentalmente, estágio não é regido pela CLT e, portanto, não gera vínculo empregatício se constituindo como uma atividade de preparação prática para o exercício de uma profissão. Por outro lado, o jovem aprendiz é regido pela CLT, tem carteira assinada, direitos trabalhistas e suas atividades laborais não têm relação com sua formação profissional – nesse caso, a prática é feita na empresa e a teoria, fora dela. Ainda, apenas médias e grandes empresas têm obrigação legal de contratar jovens aprendizes, enquanto um profissional liberal, por exemplo, pode contratar um estagiário.",
  },
  {
    id: 5,
    question: "O estágio configura uma relação de emprego?",
    answer:
      "Não. O estágio não caracteriza vínculo de emprego de qualquer natureza, desde que observados os requisitos legais, não sendo devidos encargos sociais, trabalhistas e previdenciários.",
  },
  {
    id: 6,
    question: "Pode ser concedido estágio a estudantes estrangeiros?",
    answer:
      "Sim. Segundo a legislação vigente, os estudantes estrangeiros regularmente matriculados em cursos superiores no Brasil, autorizados ou reconhecidos, podem se candidatar ao estágio, desde que o prazo do visto temporário de estudante seja compatível com o período previsto para o desenvolvimento das atividades.",
  },
  {
    id: 7,
    question:
      "Pode haver a participação dos agentes de integração no processo do estágio?",
    answer:
      "Sim. Pode ocorrer por opção das instituições de ensino e das partes concedentes de estágio mediante condições acordadas em instrumento jurídico apropriado.",
  },
  {
    id: 8,
    question: "Qual o papel dos agentes de integração no estágio?",
    answer:
      "Atuar como auxiliares no processo de aperfeiçoamento do estágio identificando as oportunidades, ajustando suas condições de realização, fazendo o acompanhamento administrativo, encaminhando negociação de seguros contra acidentes pessoais e cadastrando os estudantes, selecionando os locais de estágio e organizando o cadastro das concedentes das oportunidades de estágio.",
  },
  {
    id: 9,
    question: "Qual o prazo de duração do estágio?",
    answer:
      "Até dois anos, para o mesmo concedente, exceto quando se tratar de estagiário portador de deficiência.",
  },
  {
    id: 10,
    question: "Quando o estágio será necessariamente remunerado?",
    answer:
      "Para o estágio não obrigatório é necessário o pagamento de bolsa ou outra forma de contraprestação que venha a ser acordada, bem como a concessão do auxílio-transporte.",
  },
  {
    id: 11,
    question: "O que é o auxílio-transporte? ",
    answer:
      "É uma concessão pela instituição contratante do estágio para auxiliar nas despesas de deslocamento do estagiário ao local de estágio e seu retorno, tendo que ser necessariamente pago quando se tratar de estágio não obrigatório. Pode ser substituída por transporte próprio da empresa, sendo que ambas as alternativas deverão constar de contrato de estágio.",
  },
  {
    id: 12,
    question:
      "O estagiário tem direito ao seguro contra acidentes pessoais? Qual a cobertura do seguro?",
    answer:
      "Sim. A cobertura deve abranger acidentes pessoais ocorridos com o estudante durante o período de vigência do estágio, 24 horas/ dia, no território nacional. Cobre morte ou invalidez permanente, total ou parcial, provocadas por acidente. O valor da indenização deve constar do Certificado Individual de Seguro de Acidentes Pessoais e deve ser compatível com os valores de mercado.",
  },
  {
    id: 13,
    question: "O contratante poderá disponibilizar benefícios ao estagiário?",
    answer:
      "A empresa poderá voluntariamente conceder ao estagiário outros benefícios, como: alimentação, acesso a plano de saúde, dentre outros, sem descaracterizar a natureza do estágio.",
  },
  {
    id: 14,
    question: "Qual a duração permitida para a jornada diária de estágio?",
    answer:
      "A jornada do estagiário será definida de comum acordo entre a instituição de ensino, a empresa e o aluno e deverá constar do contrato de estágio. Deverá ser compatível com as atividades escolares e respeitar os seguintes limites: quatro horas diárias (vinte horas semanais) para estudantes de educação especial e dos anos finais do ensino fundamental, na modalidade profissional de educação de jovens e adultos e; seis horas diárias e trinta horas semanais, no caso de estudantes do ensino superior, da educação profissional de nível médio e do ensino médio regular. Também são admissíveis oito horas diárias, em cursos que alternam teoria e prática, em períodos em que não haja aulas presenciais. ",
  },
  {
    id: 15,
    question:
      "De que forma poderão ser concedidas férias (recesso) ao estagiário? ",
    answer:
      "Considerando que o estágio poderá ter duração de até 24 meses, dentro de cada período de 12 meses o estagiário deverá ter um recesso de 30 dias, que poderá ser concedido em período contínuo ou fracionado, conforme estabelecido no contrato de estágio. O recesso será concedido, preferencialmente, durante o período de férias escolares e de forma proporcional em contratos com duração inferior a 12 meses.",
  },
];

export default faq;
