import { get, post, put, remove } from "../config/api_methods";

export const getCompanies = () =>
  new Promise((resolve) => {
    get(`/companies`).then((response) => {
      const { data } = response.data;
      resolve(data.content);
    });
  });

export const getCompanyById = (id) => {
  const response = get(`/companies/${id}`);
  return response;
};

export const createCompany = (params) => {
  const response = post(`/companies`, params);
  return response;
};

export const editCompany = (id) => {
  const response = put(`/companies/${id}`);
  return response;
};

export const removeCompany = (id) => {
  const response = remove(`/companies/${id}`);
  return response;
};

export const uploadLogoCompany = (companyId, params) => {
  const response = post(`/companies/${companyId}/logo`, params);
  return response;
};
