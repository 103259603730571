import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  container: {
    padding: 30,
  },
  boxContacts: {
    width: "100%",
    border: "1px solid #EEF1F2",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    padding: "25px 30px",
  },
  boxContactsContent: {
    display: "flex",
    flexDirection: "row",
  },
  contactTitle: {
    "& h4": {
      fontFamily: "Rubik",
      fontWeight: "500",
      fontSize: "26px",
      color: "#000",
    },
  },
  contactItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: "20px 50px 0px 0px",
    "& p": {
      fontSize: "20px",
      marginLeft: "15px",
      color: "#575756",
      fontWeight: "400",
    },
    "& svg": {
      color: "#002F49",
    },
  },
  boxSeparator: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: "30px 0",
    "& h4": {
      fontFamily: "Rubik",
      fontWeight: "500",
      fontSize: "26px",
      color: "#000",
    },
  },
  form: {
    backgroundColor: "#EEF1F2",
    boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: 6,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "25px",
    padding: theme.spacing(3),
  },
  title: {
    width: "100%",
    fontFamily: "Rubik",
    fontWeight: "500",
    fontSize: "26px",
    color: "#000",
  },
  textField: {
    width: "95%",
    backgroundColor: "white",
    marginBottom: "20px",
    margin: "0",
    color: "#000",
    "& label": {
      color: "#002F49",
    },
    "@media (max-width: 940px)": {
      width: "100%",
    }
  },
  textFieldMultiline: {
    width: "97.5%",
    backgroundColor: "white",
    margin: "0",
    color: "#000",
    "& label": {
      color: "#002F49",
    },
  },
  button: {
    margin: "0 0px 0 auto",
  },
  helperText: {
    fontSize: "15px",
  },
  gridContainer: {
    alignItems: "space-between",
    margin: "0",
  },
  buttonContainner: {
    width: "97.5%",
    justifyContent: "flex-end",
  },
}));

export default styles;
