import { Switch, Route, BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import HomePage from "./pages/HomePage";
import Profile from "./pages/Profile";
import Contact from "./pages/Contact";
import Oportunity from "./pages/Oportunity";
import Oportunities from "./pages/Oportunities";
import FAQ from "./pages/FAQ";
import MyProcess from "./pages/MyProcess";
import Resume from "./pages/Resume";
import ChangePassword from "./pages/ChangePassword";
import ResetPassword from "./pages/ResetPassword";
import Login from "./pages/Login/Login";
import ContactBusiness from "./pages/ContactBusiness";
import ContactInstitution from "./pages/ContactInstitution";
import About from "./pages/About";
import Services from "./pages/Services";
import Benefits from "./pages/Benefits";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import SignUp from "./pages/SignUp/SignUp";

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const Routes = () => (
  <BrowserRouter >
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/perfil" exact component={Profile} />
      <Route path="/contato" exact component={Contact} />
      <Route path="/contato-empresa" exact component={ContactBusiness} />
      <Route path="/contato-ie" exact component={ContactInstitution} />
      <Route path="/oportunidade/:id" exact component={Oportunity} />
      <Route path="/oportunidades" exact component={Oportunities} />
      <Route path="/legislacao" exact component={FAQ} />
      <Route path="/sobre" exact component={About} />
      <Route path="/beneficios" exact component={Benefits} />
      <Route path="/servicos" exact component={Services} />
      <Route path="/meus-processos" exact component={MyProcess} />
      <Route path="/curriculo" exact component={Resume} />
      <Route path="/trocar-senha" exact component={ChangePassword} />
      <Route path="/resetar-senha" exact component={ResetPassword} />
      <Route path="/recuperar-senha" exact component={ForgotPassword} />
      <Route path="/login" exact component={Login} />
      <Route path="/cadastro" exact component={SignUp} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
