import { get, post, put, remove } from '../config/api_methods';

export const getCourses = () => {
  const response = get(`/courses-info?page=0&size=1000`);
  return response;
};

export const getCoursesById = (courseId) => {
  const response = get(`/courses-info/${courseId}`);
  return response;
};

export const editCourse = (courseId, params) => {
  const response = put(`/courses-info/${courseId}`, params);
  return response;
};

export const addCourse = (params) => {
  const response = post(`/courses-info`, params);
  return response;
};

export const removeCourse = (courseId) => {
  const response = remove(`/courses-info/${courseId}`);
  return response;
};
