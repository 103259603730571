import MomentUtils from '@date-io/moment';
import { Button } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import {
  addStudentExperience,
  getStudentExperiences,
  removeStudentExperience,
} from '../../../common/api/student/experiences';
import ExperienceCard from '../../../components/ExperienceCard/ExperienceCard';
import InputProfile from '../../../components/InputProfile/InputProfile';
import useStyles from '../styles';

export default function ExperienceForm(props) {
  const { id } = props;
  const classes = useStyles();
  const [experience, setExperience] = useState({
    company: "",
    description: "",
    occupation: "",
    endDate: moment(),
    startDate: moment(),
  });
  const [studentExperiences, setStudentExperiences] = useState([]);

  const settings = async () => {
    const response = await getStudentExperiences();
    setStudentExperiences(response.data.data.content);
  };

  const AddExperience = async () => {
    await addStudentExperience(experience);
    settings();
  };

  const RemoveExperience = async (experienceId) => {
    await removeStudentExperience(experienceId);
    settings();
  };

  // FALTA CRIAR O FORMULÁRIO DE EDIÇÃO
  // const EditExperience = async (experienceId) => {
  //   await editStudentExperience(experienceId, experience);
  //   settings();
  // };

  useEffect(() => {
    settings();
  }, [id]);

  const handleValues = (event) => {
    setExperience({
      ...experience,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <h2>Experiências Profissionais</h2>

      <div className={classes.cardsBox}>
        {studentExperiences?.map((exp) => (
          <ExperienceCard
            key={exp.id}
            experience={exp}
            remove={() => RemoveExperience(exp.id)}
            // edit={() => EditExperience(exp.id)}
          />
        ))}
      </div>

      <div className={classes.experiencesInputs}>
        <InputProfile
          onChange={handleValues}
          name="company"
          label="Empresa"
          value={experience.company}
          widthSet="45%"
        />
        <InputProfile
          onChange={handleValues}
          name="occupation"
          label="Ocupação"
          value={experience.occupation}
          widthSet="45%"
        />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            inputVariant="outlined"
            variant="inline"
            openTo="month"
            label="Data Inicial"
            className={classes.datePicker}
            views={["year", "month"]}
            value={experience.startDate}
            onChange={(value) =>
              setExperience({ ...experience, startDate: value })
            }
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            inputVariant="outlined"
            variant="inline"
            className={classes.datePicker}
            openTo="month"
            label="Data Final"
            views={["year", "month"]}
            value={experience.endDate}
            onChange={(value) =>
              setExperience({ ...experience, endDate: value })
            }
          />
        </MuiPickersUtilsProvider>
        <InputProfile
          onChange={handleValues}
          name="description"
          label="Descrição"
          value={experience.description}
          widthSet="91%"
          multiline
          rows="4"
        />
        <Button
          className={classes.addButton}
          type="button"
          onClick={AddExperience}
        >
          +
        </Button>
      </div>
    </>
  );
}

ExperienceForm.propTypes = {
  id: PropTypes.number.isRequired,
};
