/* eslint-disable no-unused-vars */
import React from "react";
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

import { TextField } from "@material-ui/core";
import useStyles from './styles';

export default function OportunitySearchInput(props) {
  const { customMargin, handleChange, onSubmit} = props;
  const classes = useStyles({ customMargin });

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <TextField
          variant="outlined"
          label="Pesquisar por Cidade"
          type="text"
          className={classes.input}
          onChange={handleChange}
          name="city"
        />
        <TextField
          variant="outlined"
          label="Pesquisar por Processos"
          type="text"
          className={classes.input2}
          onChange={handleChange}
          name="title"
        />
      <button type="submit" className={classes.search}>
        <SearchIcon fontSize="large" />
      </button>
    </form>
  );
}

OportunitySearchInput.propTypes = {
  customMargin: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

OportunitySearchInput.defaultProps = {
  customMargin: false,
};

