/* eslint-disable react/require-default-props */
import React from "react";
import { Link } from "@material-ui/core";
import PropTypes from "prop-types";
import Logo from "../../../assets/logos/logo-itai.png";
import facebookLogo from "../../../assets/icons/facebookIconWhite.svg";
import instagramLogo from "../../../assets/icons/instagramIconWhite.svg";
import linkedInLogo from "../../../assets/icons/linkedinIconWhite.svg";
import uniamericaLogo from "../../../assets/icons/logoUniamericaWhite.svg";

import useStyles from "../styles/ModalStyle";

export default function ModalLogoAndIcons(props) {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapperLeft}>
      <Link href="https://www.itai.org.br/" target="_blank">
        <img src={Logo} alt="Logo" height={130} />
      </Link>
      <div className={classes.socialNetwork}>
        <Link href="https://www.facebook.com/itai.org.br" target="_blank">
          <img src={facebookLogo} alt="Facebook Logo" />
        </Link>
        <Link href="https://www.instagram.com/itai.org.br/" target="_blank">
          <img src={instagramLogo} alt="Instagram Logo" />
        </Link>
        <Link href="https://www.linkedin.com/company/itai/" target="_blank">
          <img src={linkedInLogo} alt="Linkedin Logo" />
        </Link>
        <Link href="https://uniamerica.br/" target="_blank">
          <img src={uniamericaLogo} alt="Uniamerica Logo" />
        </Link>
      </div>
      {children}
    </div>
  );
}

ModalLogoAndIcons.propTypes = {
  children: PropTypes.element,
};
