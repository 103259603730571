import { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import FaqTitle from "../../assets/images/faqTitle.png";
import Cartilha from "../../assets/pdf/cartilha.pdf";

import styles from "./styles";
import faq from "../../assets/data/faq";

function FAQ() {
  const classes = styles();
  const [expandedId, setExpandedId] = useState(-1);

  const handleExpandClick = (index) => {
    setExpandedId(expandedId === index ? -1 : index);
  };
  return (
    <>
      <Header />
      <HeaderTitle title="FAQ" image={FaqTitle} />
      <section className={classes.container}>
        <section className={classes.resume}>
          <Typography component="body1" className={classes.title}>
            O estágio tem se mostrado o instrumento mais eficaz de preparação dos estudantes para enfrentarem os desafios profissionais, pois no ambiente de trabalho o jovem coloca em prática o que aprende e antecipa o desenvolvimento de atitudes e comportamentos profissionais, estimulando o senso crítico, a criatividade e a inovação, sem prejuízo de sua formação acadêmica.
          </Typography>
          <Typography component="body1" className={classes.title}>O estágio é regido pela <a href="http://www.planalto.gov.br/ccivil_03/_ato2007-2010/2008/lei/l11788.htm" target="_blank" rel="noreferrer" className={classes.link}>Lei nº 11.788</a>, de 25 de setembro de 2008, não gera vínculo empregatício, mas, é, em geral, remunerado. O estagiário faz jus, ainda, a auxílio-transporte e recesso remunerado.
          </Typography>
          <Typography component="body1" className={classes.title}>
            Quer entender sobre a <span><a href="http://www.planalto.gov.br/ccivil_03/_ato2007-2010/2008/lei/l11788.htm" target="_blank" rel="noreferrer" className={classes.link}>Lei nº 11.788</a></span>, acesse a <span><a href={Cartilha} target="_blank" rel="noreferrer" className={classes.link}>Nova Cartilha Esclarecedora Sobre A Lei do Estágio.</a></span>
          </Typography>
        </section>
        <section className={classes.questions}>
          {faq.map((item, pos) => (
            <Card className={classes.card} key={item.id}>
              <CardActions disableSpacing className={classes.header}>
                <Typography variant="body2" color="textSecondary" component="p">
                  {`${pos + 1}. ${item.question}`}
                </Typography>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedId === pos,
                  })}
                  onClick={() => handleExpandClick(pos)}
                  aria-expanded={expandedId === pos}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse in={expandedId === pos} timeout="auto" unmountOnExit>
                <CardContent className={classes.content}>
                  <Typography paragraph>{item.answer}</Typography>
                </CardContent>
              </Collapse>
            </Card>
          ))}
        </section>
      </section>
      <Footer />
    </>
  );
}

export default FAQ;
