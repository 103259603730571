/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
import { Button, Typography } from "@material-ui/core";
import { useContext, useState, useEffect } from "react";
import AppContext from "../../../AppContext";
import {
  addRegistration,
  isStudentRegisteredInSelectionProcess,
} from "../../../common/api/student/registrations";
import {
  oportunityStage,
  oportunityStageColor,
} from "../../../common/enum/oportunityStage";
import authenticationUtils from "../../../common/security/token";
import oportunityContext from "../context/OportunityContext";

import styles from "../styles";

export default function Oportunity() {
  const { internship } = useContext(oportunityContext);
  const { showAlert } = useContext(AppContext);
  const [disabled, setDisabled] = useState(true);
  const [stage, setStage] = useState("Aberto");
  const [color, setColor] = useState("green");
  const [isRegistered, setIsRegistered] = useState(false);
  const classes = styles();

  const handleSubscription = async () => {
    try {
      await addRegistration(internship.id);
      showAlert("success", "Você se cadastrou neste processo seletivo!");
      setDisabled(true);
      setIsRegistered(true)
    } catch (error) {
      if (error.response.status === 400) {
        showAlert("error", "Você já está inscrito neste processo seletivo");
      }
      if (error.response.status === 409) {
        showAlert(
          "error",
          "Esse processo seletivo não esta mais aceitando inscrições"
        );
      }
    }
  };

  const verifyRegistered = async (auth) => {
    const { data } = await isStudentRegisteredInSelectionProcess(internship.id);
    if (data.message) {
      setIsRegistered(true);
    } else if (auth && internship.stage === "OPEN") {
      setDisabled(false);
      setIsRegistered(false);
    }
  };

  function defineStage() {
    Object.entries(oportunityStage).map(([key, value]) =>
      key === internship.stage ? setStage(value) : null
    );
    Object.entries(oportunityStageColor).map(([key, value]) =>
      key === internship.stage ? setColor(value) : null
    );
  }

  useEffect(() => {
    defineStage();
    verifyRegistered(authenticationUtils.isAuthenticated());
  }, [authenticationUtils.isAuthenticated, internship]);

  return (
    <section className={classes.description}>
      <Typography variant="h5">
        <span className={classes.signal} style={{ backgroundColor: color }} />
        {internship.title}
        {isRegistered ? (
          <span className={classes.tag}>Você está inscrito neste processo</span>
        ) : null}
      </Typography>
      <Typography variant="body1" component="p">
        <strong>Cursos: </strong>
        {internship.courses?.map((course) => `${course.name}, `)}
      </Typography>
      <div style={{ display: "flex", gap: "30px" }}>
        <Typography variant="body1" component="p">
          <strong>Prazo de Inscrição: </strong>
          {internship.registrationEnd}
        </Typography>
        <Typography variant="body1" component="p">
          <strong>Status do Processo: </strong> {stage}
        </Typography>
      </div>
      <Typography variant="h4">Benefícios</Typography>
      {internship.benefits?.split("<br />").map((s, pos) => (
        <Typography variant="body1" component="p" key={pos}>
          {s}
        </Typography>
      ))}
      <Typography variant="h4">Descrição da Atividade</Typography>
      <Typography variant="body1" component="p">
        {internship.activitiesDescription}
      </Typography>
      <Typography variant="h4">Requisitos Desejáveis</Typography>
      {internship.requirements?.split("<br />").map((s, pos) => (
        <Typography variant="body1" component="p" key={pos}>
          {s}
        </Typography>
      ))}
      <Button
        size="large"
        type="button"
        variant="contained"
        color="secondary"
        onClick={handleSubscription}
        disabled={disabled}
        className={classes.button}
      >
        Candidatar-se
      </Button>
    </section>
  );
}
