import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },

  appBar: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
  },

  header: {
    width: "100%",
    height: "140px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    padding: "0 4rem 0 auto",

    background: "#FFFFFF",
  },

  button: {
    margin: theme.spacing(1),
  },

  menuButton: {
    "& svg": {
      width: "2.8rem",
      height: "2.8rem",
    },
  },

  list: {
    width: "250px",
    height: "100vh",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },

  loginButton: {
    width: "fit-content",
  },

  socialNetwork: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",
    boxSizing: "border-box",
    paddingTop: "15px",

    "& img": {
      padding: "0.5rem",
    },
    [theme.breakpoints.down("md")]: {
      flex: 0,
      "& img": {
        padding: "0.2rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0",
      gap: "10px",
      paddingTop: "0",
    }
  },

  navLink: {
    display: "flex",
    flex: "2",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: 0,
      "& a": {
        marginBottom: theme.spacing(3),
      },
    },
  },

  navLinkMobile: {
    display: "flex",
    flexDirection: "column",
    justifySelf: "flex-start",
    width: "100%",
    alignItems: "left",
    "& a": {
      padding: "15px 20px",
      fontWeight: "500",
      fontSize: "1.2rem",
      lineHeight: "21px",
      justifyContent: "left",
      borderBottom: "1px solid #c4c4c4",
      display: "flex",
      alignItems: "center",
      gap: "10px",
      [theme.breakpoints.down("sm")]: {
        "& svg": {
          height: "2rem",
          width: "2rem",
        }
      }
    },
    "& a:hover": {
      backgroundColor: "#f4f4f4",
    },
  },

  menuDiv: {
    borderTop: "1px solid #F7C601",
    width: "100%",
  },

  logoutButton: {
    margin: theme.spacing(1),
    minWidth: "fit-content",
  },

  dropdown: {
    display: "flex",
    gap: "10px",
    color: "#002F49",

    "& a": {
      display: "flex",
      gap: "8px",
      width: "100%",
      alignItems: "center",
    },

    "& a:hover": {
      fontWeight: "400",
    },
  }
}));

export default useStyles;
