import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@material-ui/core";
import useStyles from "../../pages/Resume/styles";

export default function LanguageCard({ item, onClick }) {
  const classes = useStyles();
  const { language, level } = item;

  function translate(lvl) {
    if (lvl === "ADVANCED") {
      return "Avançado";
    }
    if (lvl === "INTERMEDIATE") {
      return "Intermediário";
    }
    if (lvl === "BASIC") {
      return "Básico";
    }
    if (lvl === "FLUENT") {
      return "Fluente";
    }
    return "";
  }

  return (
    <div className={classes.card}>
      <div>
        <h4>{language?.name}</h4>
        <p>{translate(level)}</p>
      </div>
      <Button className={classes.delete} type="button" onClick={onClick}>
        <DeleteIcon />
      </Button>
    </div>
  );
}

LanguageCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    language: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
    }).isRequired,
    level: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};
