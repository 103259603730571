import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  body: {
    height: "calc (100vh - 300px)",
    display: "flex",
    padding: "20px",
    margin: "0 auto",
    maxWidth: "1400px",
  },
  form: {
    width: "100%",
    backgroundColor: "#EEF1F2",
    borderRadius: "6px",
    padding: "20px",
    marginTop: "20px",
    maxWidth: "950px",

    "& h2": {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: "24px",
      padding: "20px",
      borderBottom: "1.5px solid #BBB",
      marginBottom: "20px",
    },
  },
  inputForm: {
    width: "100%",
    backgroundColor: "white",
    marginTop: 15,
    color: "black",

    "& label": {
      color: "#002F49",
    },
  },
  buttonView: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  saveButton: {
    display: "flex",
    alignSelf: "flex-end",
    textAlign: "center",
    backgroundColor: "#002F49",
    width: "165px",
    height: "46px",
    fontSize: "1.4rem",
    lineHeight: "20px",
    color: "white",
    textTransform: "capitalize",
    margin: "20px 0",

    "&:hover": {
      background: "#001E38",
    },
  },
}));

export default useStyles;
