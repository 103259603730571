import JwtDecode from "jwt-decode";
import { get, put, remove, patch } from "../config/api_methods";
import authenticationUtils from "../../security/token";

const token = authenticationUtils.getToken();

export const updateUserActive = (params) => {
  const decode = JwtDecode(token);
  const id = decode.userId;
  const response = put(`/users/${id}/`, params);
  return response;
};

export const updatePassword = (params) => {
  const id = authenticationUtils.getUserId();
  const response = patch(`/users/${id}/changePassword`, params);
  return response;
};

export const removeUserActive = () => {
  const decode = JwtDecode(token);
  const id = decode.userId;
  const response = remove(`/users/${id}/`);
  return response;
};

export const getUserActive = () => {
  const response = get(`/users/profile`);
  return response;
};
