import authenticationUtils from "../../security/token";
import { get, post, remove } from "../config/api_methods";

export const addRegistration = (selectionProcessId) => {
  const id = authenticationUtils.getId();
  const response = post(
    `/students/${id}/selection-processes/${selectionProcessId}/registrations`
  );
  return response;
};

export const getRegistrations = () => {
  const id = authenticationUtils.getId();
  const response = get(`/students/${id}/registrations`);
  return response;
};

export const removeRegistration = (selectionProcessId, registrationId) => {
  const id = authenticationUtils.getId();
  const response = remove(
    `/students/${id}/selection-processes/${selectionProcessId}/registrations/${registrationId}`
  );
  return response;
};

export const isStudentRegisteredInSelectionProcess = (selectionProcessId) => {
  const response = get(`/students/is-registered/${selectionProcessId}`);
  return response;
};
