import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@material-ui/core";
import useStyles from "./styles";
import { enrollmentSituation } from "../../common/enum/enrollmentSituation";

export default function EnrollmentCard({ enrollment, remove }) {
  const classes = useStyles();
  const { course, startDate, endDate, situation } = enrollment;
  const [translatedSituation, setTranslatedSituation] = useState("");
  const [data, setData] = useState("");

  useEffect(() => {
    Object.entries(enrollmentSituation).map(([key, value]) =>
      key === situation ? setTranslatedSituation(value) : null
    );
    const alteredStartData = startDate.split("-").reverse().join("/");
    const alteredEndData = endDate.split("-").reverse().join("/");
    setData(`De ${alteredStartData} a ${alteredEndData}`);
  }, [])

  return (
    <div className={classes.card}>
      <div>
        <h4>
          {course.courseInfo.name} - {course.educationalInstitution.businessName}
        </h4>
        <p>
          {data}
        </p>
        <p>{translatedSituation}</p>
      </div>
      <Button className={classes.button} type="button" onClick={remove}>
        <DeleteIcon />
      </Button>
    </div>
  );
}

EnrollmentCard.propTypes = {
  enrollment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    course: PropTypes.shape({
      courseInfo: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      educationalInstitution: PropTypes.shape({
        businessName: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    situation: PropTypes.string.isRequired,
  }).isRequired,
  remove: PropTypes.func.isRequired,
};
