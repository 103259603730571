/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
  },
  details: {
    display: "flex",
    alignItems: "center",
  },
}));

// eslint-disable-next-line react/prop-types
// const AccordionChild = React.forwardRef(({ title, children }, ref) => {
//   const classes = useStyles();

//   return (
//     <Accordion ref={ref}>
//       <AccordionSummary
//         expandIcon={<ExpandMoreIcon />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//       >
//         <Typography className={classes.heading}>{title}</Typography>
//       </AccordionSummary>
//       <AccordionDetails className={classes.details}>
//         {children}
//       </AccordionDetails>
//     </Accordion>
//   );
// });

// eslint-disable-next-line react/prop-types
function SimpleAccordion({ title, children }) {
  const classes = useStyles();
  return (
    <div>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default SimpleAccordion;
