import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PropTypes from "prop-types";
import { FormHelperText } from "@material-ui/core";
import useStyle from "../styles";

const PasswordTextField = ({ helperText, error, name, label, ...props }) => {
  const classes = useStyle();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={error}
      className={classes.textField}
    >
      <InputLabel htmlFor="passwordInput">{label}</InputLabel>
      <OutlinedInput
        {...props}
        name={name}
        fullWidth
        required
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Muda visibilidade da senha"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={50}
      />
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
};

PasswordTextField.propTypes = {
  helperText: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
};

PasswordTextField.defaultProps = {
  helperText: "",
  error: false,
  name: "password",
  label: "Senha",
};

export default PasswordTextField;
