import { get, post, put, remove } from "../config/api_methods";

export const getStudentProfile = () => {
  const response = get(`/students`);
  return response;
};

export const getStudentProfileByID = (id) => {
  const response = get(`/students/${id}`);
  return response;
};

export const createStudentProfile = (params) => {
  const response = post(`/students`, params);
  return response;
};

export const updateProfile = (params, id) => {
  const response = put(`/students/${id}`, params);
  return response;
};

export const removeProfile = (id) => {
  const response = remove(`/students/${id}/`);
  return response;
};
