import { Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import HeaderTitle from "../HeaderTitle/HeaderTitle";

import styles from "./styles";

export default function TextPictureContent({elements, title, image}) {
  const classes = styles();

  return (
    <>
      <Header />
      <HeaderTitle title={title} image={image} />
      {elements.map((element, index) => (
        <>
          <section className={classes.container}>
              {index%2 === 0 ? null : <img src={element.image} alt={element.title} />}
              <div>
                  <Typography variant="h3" component="h3">{element.title}</Typography>
                  <Typography variant="body2"component="p">{element.description}</Typography>
              </div>
              {index%2 === 0 ? <img src={element.image} alt={element.title} /> : null}
          </section>
          <hr className={classes.separator} />
        </>
      ))}
      <Footer />
    </>
  );
}

TextPictureContent.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
