import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  interMenu: {
    width: "300px",
    minWidth: "300px",
    maxWidth: "300px",
    margin: "20px",
    backgroundColor: "#EEF1F2",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    height: "fit-content",

    "& a, button": {
      padding: "20px 25px",
      fontSize: "1.3rem",
      borderTop: "1.5px solid #BBB",
      borderRadius: "0",
      fontFamily: "Roboto",
      textTransform: "capitalize",
      fontWeight: "400",
      width: "100%",
      cursor: "pointer",
      alignItems: "center",
      display: "flex",
      textAlign: "left",
      justifyContent: "flex-start",
      color: "#313131",
    },

    "& button": {
      borderRadius: "0 0 4px 4px",
    },

    "& svg": {
      marginRight: "10px",
    },

    "& a:hover, button:hover": {
      backgroundColor: "#2B708B",
      color: "white",
    },
  },

  menuTitle: {
    margin: "30px 5px 0px",
    fontFamily: "Roboto",
    fontWeight: "500",
    textTransform: "capitalize",
  },

  menuSubTitle: {
    margin: "15px 5px 15px",
    fontFamily: "Roboto",
    fontWeight: "500",
  },
}));

export default useStyles;
