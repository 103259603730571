/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Slider from "react-slick";
import { Typography } from "@material-ui/core";

import useStyles from "./styles";

import partners from "../../assets/data/popularPartners";

import arrowRight from "../../assets/icons/arrowRight.svg";
import arrowLeft from "../../assets/icons/arrowLeft.svg";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", marginTop: "-40px" }}
      onClick={onClick}
    >
      <img src={arrowRight} alt="Ir para direita" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", marginTop: "-40px" }}
      onClick={onClick}
    >
      <img src={arrowLeft} alt="Ir para esquerda" />
    </div>
  );
}

function Partners() {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={classes.grid}>
      <Typography variant="h2">Parceiros</Typography>

      <Slider {...settings}>
        {partners.map((partner) => (
          <div className={classes.box} key={partner.id}>
            <img src={partner.partnerImage} alt={partner.title} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Partners;
