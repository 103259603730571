import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { SwipeableDrawer, Box } from "@material-ui/core";
import { 
  Home, 
  Person, 
  ExitToApp, 
  Business, School, 
  ContactMail, 
  Work, 
  LockOpen, 
  AddBox 
} from "@material-ui/icons";

import { NavLink } from "react-router-dom";
import logoHorizontalItai from "../../../assets/logos/logoHorizontalItai.svg";
import SocialNetworkIcons from "./SocialNetworkIcons";
import useStyles from "../styles/Headerstyle";
import Modal from "../../Modal/Modal";

import authenticationUtils from "../../../common/security/token";

export default function MobileMenu() {
  const classes = useStyles();
  const [menu, setMenu] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setMenu(false);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleLogout = () => {
    authenticationUtils.removeToken();
  };

  return (
    <>
      <Modal opened={modalOpen} handleModalClose={handleModalClose} activatedStep={1} />
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={() => setMenu(true)}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={menu}
        onClose={() => setMenu(false)}
        onOpen={() => setMenu(true)}
      >
        <div
          className={classes.list}
          role="presentation"
          onClick={() => setMenu(false)}
          onKeyDown={() => setMenu(false)}
        >
          <img src={logoHorizontalItai} alt="logo Itai" />
          <Box className={classes.navLinkMobile}>
            <NavLink to="/sobre" onClick={handleLogout}>
              <Home />
              Sobre
            </NavLink>
            <NavLink to="/oportunidades" onClick={handleModalOpen}>
              <Work />
              Vagas de Estágio
            </NavLink>
            <NavLink to="/servicos" onClick={handleModalOpen}>
              <Business />
              Empresa
            </NavLink>
            <NavLink to="/beneficios" onClick={handleLogout}>
              <School />
              Instituicão de Ensino
            </NavLink>
            <NavLink to="/contato" onClick={handleLogout}>
              <ContactMail />
              Contato
            </NavLink>
          {authenticationUtils.isAuthenticated() ? (
            <>
              <NavLink to="/perfil" onClick={handleModalOpen}>
                <Person />
                Meu Perfil
              </NavLink>
              <NavLink to="/#" onClick={handleLogout}>
                <ExitToApp />
                Sair
              </NavLink>
            </>
          ) : (
            <>
              <NavLink to="/login" onClick={handleModalOpen}>
                <LockOpen />
                Entrar
              </NavLink>
              <NavLink to="/cadastro" onClick={handleModalOpen}>
                <AddBox />
                Cadastre-se
              </NavLink>
            </>
          )}
          </Box>
          <SocialNetworkIcons />
        </div>
      </SwipeableDrawer>
    </>
  );
}
