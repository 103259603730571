import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  body: {
    display: "flex",
    padding: "20px",
    margin: "0 auto",
    maxWidth: "1400px",
  },
  form: {
    backgroundColor: "#EEF1F2",
    borderRadius: "6px",
    padding: "20px",
    marginTop: "20px",
    maxWidth: "950px",
    width: "calc(100% - 350px)",

    "& h2": {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: "24px",
      padding: "20px",
      borderBottom: "1.5px solid #BBB",
      marginBottom: "20px",
    },
  },

  inputForm: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    margin: "0",
    height: "60px",

    "& label": {
      color: "#002F49",
    },
  },

  coursesAutocomplete: {
    backgroundColor: "white",
    width: "44%",
  },

  datePicker: {
    width: "45%",
    backgroundColor: "white",
    color: "black",
    margin: "0",
    height: "60px",

    "& label": {
      color: "#002F49",
    },
  },

  cardsBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "20px",
    margin: "10px 0 20px",
  },

  card: {
    display: "flex",
    gap: "16px",
    textAlign: "left",
    width: "fit-content",
    backgroundColor: "#fbfbfb",
    color: "#616161",
    padding: "16px",
    height: "80px",
    borderRadius: "4px",
    fontSize: "1.3rem",
    fontWeight: "400",
  },

  inputsBox: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginBottom: "20px",
    gap: "10px",
  },

  experiencesInputs: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginBottom: "20px",
    flexWrap: "wrap",
    gap: "30px 10px",
  },

  enrollmentsInputs: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginBottom: "20px",
    gap: "30px 15px",
    flexWrap: "wrap",
    "& .MuiFormControl-root": {
      width: "44%",
    }
  },

  addButton: {
    display: "block",
    textAlign: "center",
    backgroundColor: "#002F49",
    width: "60px",
    height: "60px",
    fontSize: "2rem",
    lineHeight: "20px",
    color: "white",
    margin: "0",

    "&:hover": {
      background: "#001E38",
    },
  },

  changeButton: {
    textTransform: "none",
    marginBottom: "5px",
    width: "fit-content",
    textDecoration: "underline",
    color: "#2B708B",
    paddingLeft: "0px"
  },

  helperText: {
    marginLeft: "0px"
  },

  delete: {
    minWidth: "42px",
    padding: 0,

    "& .MuiSvgIcon-root": {
      width: "25px",
      height: "25px",
      color: "#515151",
    },
  },

  eIandCourseButtons: {
    display: "grid",
    gridTemplateColumns: "45% 45%",
    gap: "10px",
  }
}));

export default useStyles;
