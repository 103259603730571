import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  inputForm: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    margin: "0",
    height: "60px",

    "& label": {
      color: "#002F49",
    },
  },
}));

export default useStyles;
