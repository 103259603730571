import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Button } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/pt-br";
import { useContext } from "react";
import useStyles from "../styles";
import InputProfile from "../../../components/InputProfile/InputProfile";
import SelectResume from "../../../components/SelectResume/SelectResume";
import situationOptions from "../../../assets/data/situationOptions";
import { courseClassesPeriod } from "../../../common/enum/courseClassesPeriod";
import enrollmentContext from "../context/EnrollmentContext";
import { addStudentEnrollment } from "../../../common/api/student/enrollments";

export default function EnrollmentForm() {
  const classes = useStyles();
  const {
    settings,
    enrollment,
    institutionsOptions,
    coursesOptions,
    handleValues,
    searchInstitutionCurses,
    setEnrollment
  } = useContext(enrollmentContext);

  const AddEnrollment = async () => {
    await addStudentEnrollment({
      course: enrollment.courseInfo,
      situation: enrollment.situation,
      graduationPeriod: enrollment.graduationPeriod,
      endDate: enrollment.endDate,
      startDate: enrollment.startDate,
    });
    settings();
  };

  return (
    <div className={classes.enrollmentsInputs}>
      <SelectResume
        value={enrollment.institution}
        id="enrollment-institution-select"
        name="institution"
        label="Instituição Educacional"
        options={institutionsOptions}
        onChange={handleValues}
        onBlur={searchInstitutionCurses}
        labelOption="companyName"
      />
      <FormControl variant="outlined" className={classes.inputForm}>
        <InputLabel id="enrollment-courseInfo-select">Curso</InputLabel>
        <Select
          labelId="enrollment-courseInfo-select"
          onChange={handleValues}
          name="courseInfo"
          label="Curso"
          disabled={coursesOptions.length < 1}
          value={enrollment.courseInfo}
        >
          {coursesOptions?.map((option) => (
            <MenuItem key={option.id} value={option.courseInfo}>
              {`${option.courseInfo.name} - ${courseClassesPeriod[option.classesPeriod]}`}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText className={classes.helperText}>Selecione primeiro sua Instituição Educacional</FormHelperText>
      </FormControl>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
        <KeyboardDatePicker
          inputVariant="outlined"
          variant="inline"
          openTo="month"
          label="Data Inicial"
          className={classes.datePicker}
          views={["year", "month"]}
          value={enrollment.startDate}
          onChange={(value) =>
            setEnrollment({ ...enrollment, startDate: value })
          }
        />
      </MuiPickersUtilsProvider>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="br">
        <KeyboardDatePicker
          inputVariant="outlined"
          variant="inline"
          className={classes.datePicker}
          openTo="month"
          label="Data Final"
          views={["year", "month"]}
          value={enrollment.endDate}
          onChange={(value) =>
            setEnrollment({ ...enrollment, endDate: value })
          }
        />
      </MuiPickersUtilsProvider>
      <SelectResume
        value={enrollment.situation}
        id="enrollment-situation-select"
        name="situation"
        label="Situação"
        options={situationOptions}
        onChange={handleValues}
      />
      {enrollment.situation === situationOptions[0].value ? (
        <InputProfile
          value={enrollment.graduationPeriod}
          name="graduationPeriod"
          label="Período atual"
          required={false}
          onChange={handleValues}
          widthSet="30%"
        />
      ) : null}
      <Button
        className={classes.addButton}
        type="button"
        onClick={AddEnrollment}
      >
        +
      </Button>
    </div>
  );
}

EnrollmentForm.propTypes = {};
