import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import logoHorizontalItai from "../../assets/logos/logoHorizontalItai.svg";
import SocialNetworkIcons from "./sections/SocialNetworkIcons";
import NavLinks from "./sections/NavLinks";
import MobileMenu from "./sections/MobileMenu";
import useStyles from "./styles/Headerstyle";
import LoginLogoutButton from "./sections/LoginLogoutButton";

export default function MenuAppBar() {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Toolbar className={classes.appBar}>
          <NavLink to="/">
            <img src={logoHorizontalItai} alt="logo Itai" />
          </NavLink>
          {isMobile ? (
            <MobileMenu />
          ) : (
            <header className={classes.header}>
              <SocialNetworkIcons />
              <NavLinks />
              <LoginLogoutButton />
            </header>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
