import { Button } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  getStudentProfileByID,
  updateProfile,
} from "../../../common/api/student/profile";
import useStyles from "../styles";
import InputProfile from "../../../components/InputProfile/InputProfile";
import InputProfileMasked from "../../../components/InputsMasks/ProfileInput/InputProfileMasked";
import AppContext from "../../../AppContext";
import { userInitialValue } from "../../../assets/initialValues/userInitialValue"

export default function ProfileForm(props) {
  const { id } = props;
  const classes = useStyles();
  const [values, setValues] = useState(userInitialValue);
  const [address, setAddress] = useState({});
  const { showAlert } = useContext(AppContext);

  const settings = async () => {
    const { data } = await getStudentProfileByID(id);
    setValues(data.data);
    setAddress(data.data.address);
  };

  useEffect(() => {
    settings();
  }, [id]);

  const handleValues = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddress = (event) => {
    setAddress({
      ...address,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      id,
      address: {
        cep: address.cep.replace(/\D/g, ''),
        city: address.city,
        complement: address.complement,
        country: address.country,
        neighborhood: address.neighborhood,
        number: address.number,
        state: address.state,
        street: address.street,
      },
      name: values.name,
      email: values.email,
      phone: values.phone.replace(/\D/g, ''),
      cpf: values.cpf.replace(/\D/g, ''),
    };
    updateProfile(data, id);
    showAlert("success", "Seu perfil foi atualizado com sucesso!");
  };

  return (
    <div>
      <form className={classes.form} onSubmit={handleSubmit}>
        <h2>Informações Básicas</h2>
        <InputProfile
          name="name"
          label="Nome Completo"
          value={values.name}
          onChange={handleValues}
        />
        <InputProfileMasked
          onChange={handleValues}
          name="cpf"
          label="CPF"
          value={values.cpf}
          mask="999.999.999-99"
        />
        <InputProfile
          type="email"
          name="email"
          label="E-mail"
          value={values.email}
          onChange={handleValues}
        />
        <InputProfileMasked
          onChange={handleValues}
          name="phone"
          type="tel"
          label="Telefone"
          value={values.phone}
          mask="(99)99999-9999"
        />
        <h2>Endereço</h2>
        <InputProfile
          name="street"
          label="Rua"
          onChange={handleAddress}
          value={address.street}
        />
        <InputProfile
          onChange={handleAddress}
          name="number"
          label="Número"
          value={address.number}
        />
        <InputProfile
          name="complement"
          label="Complemento"
          required={false}
          onChange={handleAddress}
          value={address.complement}
        />
        <InputProfile
          name="neighborhood"
          label="Bairro"
          value={address.neighborhood}
          onChange={handleAddress}
        />
        <InputProfileMasked
          onChange={handleAddress}
          name="cep"
          label="CEP"
          value={address.cep}
          mask="99999-999"
        />
        <InputProfile
          onChange={handleAddress}
          name="city"
          label="Cidade"
          value={address.city}
        />
        <InputProfile
          onChange={handleAddress}
          name="state"
          label="Estado"
          value={address.state}
        />
        <InputProfile
          onChange={handleAddress}
          name="country"
          label="Pais"
          value={address.country}
        />
        <Button className={classes.saveButton} type="submit">
          Salvar
        </Button>
      </form>
    </div>
  );
}

ProfileForm.propTypes = {
  id: PropTypes.number.isRequired,
};
