import { Typography } from "@material-ui/core";
import { useContext } from "react";
import { 
  Person, 
  LocationCity, 
  Work, 
  MonetizationOn, 
} from "@material-ui/icons";

import styles from "../styles";
import CardItem from "./CardItem";
import oportunityContext from "../context/OportunityContext";
import { courseClassesPeriod } from '../../../common/enum/courseClassesPeriod';

export default function Card() {
  const { internship } = useContext(oportunityContext);
  const classes = styles();
  return (
    <section className={classes.card}>
      <Typography component="h3" variant="h4">
        Detalhes da Vaga
      </Typography>
      <CardItem
        title="Empresa"
        text={internship.company.businessName}
        // icon={internship.company.logoFilename}
      />
      <CardItem
        title="Local"
        text={internship.company.address.city}
        icon={<LocationCity/>}
      />
      <CardItem title="Salário" text={`R$ ${internship.salary}`} icon={<MonetizationOn/>} />
      <CardItem
        title="Jornada de Trabalho"
        text={courseClassesPeriod[internship.period]}
        icon={<Work/>}
      />
      <CardItem
        title="Número de vagas"
        text={internship.vacancies.toString()}
        icon={<Person/>}
      />
    </section>
  );
}
