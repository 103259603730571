import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  textField: {
    marginTop: "15px",
    color: "#002F49",
  },
}));

export default useStyles;
