/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import SignUpButton from "../SignUpButton/SignUpButton";
import authenticationUtils from "../../common/security/token";


function LateralCard({
  weight,
  content,
  goTo,
  action,
  studentName,
  studentLocal,
}) {
  const classes = useStyles();

  return (
    <>
      {action ? (
        <div className={classes.boxAction}>
          <Typography variant="h5">{content}</Typography>
          {!authenticationUtils.isAuthenticated() &&
            <Link to={goTo}>
              <SignUpButton />
            </Link>
          }
        </div>
      ) : (
        <div className={classes.box}>
          <Typography variant={weight}>{content}</Typography>
          <div className={classes.boxStudent}>
            <Typography component="h5" variant="h5">
              {studentName}
            </Typography>
            <Typography component="h6" variant="h6">
              {studentLocal}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
}

export default LateralCard;
