import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import Footer from "../../components/Footer/Footer";

import Partners from "../../components/Partners/Partners";
// import PopularCategories from "../../components/PopularCategories/PopularCategories";
import Oportunities from "./sections/Oportunities";
import useStyles from "./styles";

function HomePage() {
  const classes = useStyles();

  return (
    <div className={classes.home}>
      <Header />
      <Banner />
      <Partners />
      {/* <PopularCategories /> */}
      <Oportunities />
      <Footer />
    </div>
  );
}

export default HomePage;
