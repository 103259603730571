/* eslint-disable react/jsx-no-duplicate-props */
import 'moment/locale/pt-br';

import MomentUtils from '@date-io/moment';
import { Button, InputAdornment } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useContext } from 'react';

import classesPeriodOptions from '../../../assets/data/classesPeriodOptions';
import graduationScheduleOptions from '../../../assets/data/graduationScheduleOptions';
import situationOptions from '../../../assets/data/situationOptions';
import typeOptions from '../../../assets/data/typeOptions';
import { createEdInstitution } from '../../../common/api/educationalInstitution/educationalInstitution';
import { addStudentEnrollment } from '../../../common/api/student/enrollments';
import InputProfile from '../../../components/InputProfile/InputProfile';
import SelectResume from '../../../components/SelectResume/SelectResume';
import enrollmentContext from '../context/EnrollmentContext';
import useStyles from '../styles';

export default function NewInstitutionAndCourseForm() {
  const classes = useStyles();
  const { 
    settings, 
    enrollment, 
    handleValues, 
    setEnrollment 
  } = useContext(enrollmentContext);

  const AddEnrollment = async () => {
    const { data } = await createEdInstitution({
      businessName: enrollment.educationalInstitution,
      cnpj: enrollment.cnpj,
      companyName: enrollment.companyName,
      course: {
        classesPeriod: enrollment.classesPeriod,
        courseInfo: {
          name: enrollment.courseInfoName,
        },
        duration: enrollment.duration,
        graduationSchedule: enrollment.graduationSchedule,
        type: enrollment.type,
      },
      situation: enrollment.situation,
      graduationPeriod: enrollment.graduationPeriod,
      endDate: enrollment.endDate,
      startDate: enrollment.startDate,
    });
    await addStudentEnrollment({
      course: data.data.courses[0],
      situation: enrollment.situation,
      graduationPeriod: enrollment.graduationPeriod,
      endDate: enrollment.endDate,
      startDate: enrollment.startDate,
    });
    settings();
  };

  return (
    <div className={classes.enrollmentsInputs}>
      <InputProfile
        value={enrollment.educationalInstitution}
        name="educationalInstitution"
        label="Nome Empresarial da Instituição"
        onChange={handleValues}
      />
      <InputProfile
        value={enrollment.companyName}
        name="companyName"
        label="Nome Fantasia da Instituição"
        onChange={handleValues}
      />
      <InputProfile
        value={enrollment.cnpj}
        name="cnpj"
        label="CNPJ da Instituição"
        onChange={handleValues}
      />
      <InputProfile
        value={enrollment.courseInfoName}
        name="courseInfoName"
        label="Curso"
        onChange={handleValues}
      />
      <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
        <KeyboardDatePicker
          inputVariant="outlined"
          variant="inline"
          openTo="month"
          label="Data Inicial"
          className={classes.datePicker}
          views={["year", "month"]}
          value={enrollment.startDate}
          onChange={(value) =>
            setEnrollment({ ...enrollment, startDate: value })
          }
        />
      </MuiPickersUtilsProvider>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="br">
        <KeyboardDatePicker
          inputVariant="outlined"
          variant="inline"
          className={classes.datePicker}
          openTo="month"
          label="Data Final"
          views={["year", "month"]}
          value={enrollment.endDate}
          onChange={(value) =>
            setEnrollment({ ...enrollment, endDate: value })
          }
        />
      </MuiPickersUtilsProvider>
      <SelectResume
        value={enrollment.type}
        id="enrollment-type-select"
        name="type"
        label="Nível"
        options={typeOptions}
        onChange={handleValues}
      />
      <SelectResume
        value={enrollment.graduationSchedule}
        id="enrollment-graduationSchedule-select"
        name="graduationSchedule"
        label="Periodo de Graduação"
        options={graduationScheduleOptions}
        onChange={handleValues}
      />
      <SelectResume
        value={enrollment.classesPeriod}
        id="enrollment-classesPeriod-select"
        name="classesPeriod"
        label="Turno"
        options={classesPeriodOptions}
        onChange={handleValues}
      />
      <InputProfile
        onChange={handleValues}
        name="duration"
        label="Duração do curso"
        value={enrollment.duration}
        widthSet="30%"
        inputProps={{
          step: "0.5",
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">anos</InputAdornment>
        }}
        type="number"
        error={enrollment.duration % 0.5 !== 0}
        helperText="Insira apenas números inteiros ou com decimal 5. Ex: 2,5"
      />
      <SelectResume
        value={enrollment.situation}
        id="enrollment-situation-select"
        name="situation"
        label="Situação"
        options={situationOptions}
        onChange={handleValues}
      />
      {enrollment.situation === situationOptions[0].value ? (
        <InputProfile
          value={enrollment.graduationPeriod}
          name="graduationPeriod"
          label="Período atual"
          required={false}
          onChange={handleValues}
          widthSet="30%"
        />
      ) : null}
      <Button
        className={classes.addButton}
        type="button"
        onClick={AddEnrollment}
      >
        +
      </Button>
    </div>
  );
}
