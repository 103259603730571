/* eslint-disable no-unused-vars */
import {
  Checkbox,
  Typography,
  FormControlLabel,
  Button,
  Slider,
} from "@material-ui/core";
import { useContext, useEffect, useState, useRef } from "react";
import SimpleAccordion from "../../components/Accordion/Accordion";
import FilterIcon from "../../assets/icons/filter.svg";
import { getCompanies } from "../../common/api/companies/companies";
import { getCourses } from "../../common/api/courses/courses";
import api from "../../common/api/config/api";
import oportunitiesContext from "./context/OportunitiesContext";

import periods from "./filters/period";
import stages from "./filters/stage";

import styles from "./styles";

function valuetext(value) {
  return `R$ ${value}`;
}

export default function Filter() {
  const { getSelectionProcesses, setParams, params } = useContext(
    oportunitiesContext
  );

  const didMount = useRef(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [courseFilter, setCourseFilter] = useState([]);
  const [periodFilter, setPeriodFilter] = useState([]);
  const [stageFilter, setStageFilter] = useState([]);
  const [salaryMin, setSalaryMin] = useState(null);
  const [salaryMax, setSalaryMax] = useState(null);
  const [valueSalary, setValueSalary] = useState([]);
  const [salaryRange, setSalaryRange] = useState({});

  const classes = styles();

  useEffect(async () => {
    const res = await api.get("/selection-processes/min-max-salary");
    setSalaryRange(res.data);
    setValueSalary(Object.values(res.data));

    const companies = await getCompanies();
    setAllCompanies(
      companies.map((company) => ({
        name: company.businessName,
      }))
    );

    const courses = await getCourses();
    setAllCourses(courses.data.data.content);
  }, []);

  const handleCompanyFilter = (e) => {
    const { name, checked } = e.target;

    if (checked) {
      setCompanyFilter((prevState) => [...prevState, name]);
    } else {
      setCompanyFilter(companyFilter.filter((company) => company !== name));
    }
  };

  const handlePeriodFilter = (e) => {
    const { checked, value } = e.target;

    if (checked) {
      setPeriodFilter((prevState) => [...prevState, value]);
    } else {
      setPeriodFilter(periodFilter.filter((period) => period !== value));
    }
  };

  const handleStageFilter = (e) => {
    const { checked, value } = e.target;

    if (checked) {
      setStageFilter((prevState) => [...prevState, value]);
    } else {
      setStageFilter(stageFilter.filter((stage) => stage !== value));
    }
  };

  const handleCourseFilter = (e) => {
    const { checked, value } = e.target;

    if (checked) {
      setCourseFilter((prevState) => [...prevState, value]);
    } else {
      setCourseFilter(courseFilter.filter((course) => course !== value));
    }
  };

  const handleSalaryFilter = (e, newValue) => {
    setValueSalary(newValue);
    setSalaryMin(newValue[0]);
    setSalaryMax(newValue[1]);
  };
   
  useEffect(() => {
    if (didMount.current) {
      setParams(() => ({
        size: 5,
        period: periodFilter.toString() || null,
        stage: stageFilter.toString() || null,
        companyName: companyFilter.toString() || null,
        courseId: courseFilter.toString() || null,
        salaryMin,
        salaryMax,
      }))
    } else {
      didMount.current = true;
    }
  }, [
    companyFilter,
    salaryMin,
    salaryMax,
    periodFilter,
    stageFilter,
    courseFilter,
  ]);


  return (
    <section className={classes.card}>
      <div className={classes.cardTitle}>
        <Typography className={classes.heading}>
          <img src={FilterIcon} alt="Icone" />
          Filtrar por
        </Typography>
        <button type="button">Apagar Filtros</button>
      </div>
      <div className={classes.cardContent}>
        <SimpleAccordion title="Cursos" className={classes.accordion}>
          <div className={classes.checkbox}>
            {allCourses?.map((course) => (
              <FormControlLabel
                key={course.id}
                control={
                  <Checkbox
                    onChange={handleCourseFilter}
                    name={course.name}
                    value={course.id}
                  />
                }
                label={
                  <Typography className={classes.formControlLabel}>
                    {course.name}
                  </Typography>
                }
              />
            ))}
          </div>
        </SimpleAccordion>
        <SimpleAccordion title="Empresas">
          <div className={classes.checkbox}>
            {allCompanies?.map((company) => (
              <FormControlLabel
                key={company.name}
                control={
                  <Checkbox
                    onChange={handleCompanyFilter}
                    name={company.name}
                  />
                }
                label={
                  <Typography className={classes.formControlLabel}>
                    {company.name}
                  </Typography>
                }
              />
            ))}
          </div>
        </SimpleAccordion>
        <SimpleAccordion title="Turno">
          <div className={classes.checkbox}>
            {periods.map((period) => (
              <FormControlLabel
                key={period.id}
                control={
                  <Checkbox onChange={handlePeriodFilter} name={period.name} />
                }
                label={
                  <Typography className={classes.formControlLabel}>
                    {period.name}
                  </Typography>
                }
                value={period.value}
              />
            ))}
          </div>
        </SimpleAccordion>
        <SimpleAccordion title="Status">
          <div className={classes.checkbox}>
            {stages.map((stage) => (
              <FormControlLabel
                key={stage.id}
                control={
                  <Checkbox onChange={handleStageFilter} name={stage.name} />
                }
                label={
                  <Typography className={classes.formControlLabel}>
                    {stage.name}
                  </Typography>
                }
                value={stage.value}
              />
            ))}
          </div>
        </SimpleAccordion>
        <SimpleAccordion title="Salário">
          <span className={classes.salaryText}>R$ </span>
          <div className={classes.details}>
            <Slider
              className={classes.slider}
              value={valueSalary}
              onChange={handleSalaryFilter}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={valuetext}
              step={10}
              min={salaryRange.minSalary}
              max={salaryRange.maxSalary}
            />
          </div>
        </SimpleAccordion>

        <Button
          className={classes.button}
          onClick={() => getSelectionProcesses(params)}
        >
          Aplicar Filtros
        </Button>
      </div>
    </section>
  );
}
