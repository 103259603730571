import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  container: {
    padding: 30,
  },
  link: {
    borderBottom: "1px solid black",

    '&:hover': {
      fontWeight: "500",
    }
  },

  resume: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1200px",
    border: "1px solid #CCC",
    borderRadius: "8px",
    padding: "50px",
    margin: "0 auto",
    textAlign: "justify",
    fontFamily: "Roboto",
  },

  title: {
    marginBottom: "2rem",
  },
  
  questions: {
    color: "black",
  },
  card: {
    maxWidth: "1200px",
    borderLeft: "10px solid #2B708B",
    margin: "20px auto",
    borderRadius: "6px",
  },
  header: {
    backgroundColor: "#EEF1F2",
    color: "black",
    fontWeight: "600",
    textAlign: "left",
    padding: "20px 30px",
  },
  content: {
    padding: "20px 30px",
    lineHeight: "30px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default styles;
