const typeOptions = [
  {
    label: "Bacharel",
    value: "BACHELOR",
  },
  {
    label: "Licenciatura",
    value: "LICENTIATE",
  },
  {
    label: "Tecnólogo",
    value: "TECHNOLOGIST",
  },
  {
    label: "Técnico",
    value: "TECHNICIAN",
  },
  {
    label: "Técnico Integrado",
    value: "INTEGRATED_TECHNICIAN",
  },
  {
    label: "Ensino Médio",
    value: "HIGH_SCHOOL",
  },
];

export default typeOptions;
