/* eslint-disable no-unused-vars */
import { Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import oportunitiesTitle from '../../assets/images/oportunitiesTitle.png';
import { getSelectionProcess } from '../../common/api/selectionProcess/selectionProcess';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';
import OportunityCard from '../../components/OportunityCard/OportunityCard';
import OportunitySearchInput from '../../components/OportunitySearchInput/OportunitySearchInput';
import OportunitiesContext from './context/OportunitiesContext';
import Filter from './Filter';
import useStyles from './styles';

export default function Oportunities(props) {
  const { location } = props;
  const { state } = location;
  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = useState({});
  const [oportunitiesShowed, setOportunitiesShowed] = useState([]);
  const [params, setParams] = useState({
    city: (state !== undefined && state?.city !== undefined) ? state.city : "",
    name: null,
    size: 5,
    page: 0,
    courseId: null,
    period: null,
    stage: null,
    salaryMin: null,
    salaryMax: null,
    companyName: null ,
    title: (state !== undefined && state?.title !== undefined) ? state.title : ""
  });

  async function getSelectionProcesses() {
    const oportunities = await getSelectionProcess(params);
    setOportunitiesShowed(oportunities.content);
    setPage({
      totalPages: oportunities.totalPages,
      totalElements: oportunities.totalElements,
    });
  }

  const setSelectionProcess = (event) => {
    event.preventDefault();
    getSelectionProcesses();
  }

  useEffect(async () => {  
    getSelectionProcesses();    
    history.replace(`/oportunidades`, {});
  }, []);

  const handlePageChange = (e, p) => {
    setParams({ ...params, page: p - 1 });
  };

  const handleChange = (event) => {
    setParams({
      ...params,
      [event.target.name]: event.target.value,
    })
  }

  useEffect(() => {
    getSelectionProcesses()
  }, [params.page])


  return (
    <OportunitiesContext.Provider
      value={{
        oportunitiesShowed,
        setOportunitiesShowed,
        params,
        setParams,
        getSelectionProcesses,
      }}
    >
      <Header />
      <HeaderTitle
        title="A Forma Mais Fácil de Encontrar seu Estágio"
        image={oportunitiesTitle}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        className={classes.containerForm}
      >
        <OportunitySearchInput values={params} handleChange={handleChange} onSubmit={setSelectionProcess} customMargin/>
      </div>
      <section className={classes.container}>
        <section className={classes.filterContainer}>
          <Filter />
        </section>
        <section className={classes.oportunitiesContainer}>
          <Typography component="h3" variant="h6" className={classes.internshipLenght}>
            {page.totalElements} ESTÁGIOS ENCONTRADOS
          </Typography>
          {oportunitiesShowed?.map((internship) => (
            <OportunityCard internship={internship} key={internship.id}/>
          ))}
          {page.totalElements > params.size && (
            <Pagination
              count={page.totalPages}
              color="primary"
              onChange={handlePageChange}
            />
          )}
        </section>
      </section>
      <Footer />
    </OportunitiesContext.Provider>
  );
}

Oportunities.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired
}
