import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DescriptionIcon from "@material-ui/icons/Description";
import WorkIcon from "@material-ui/icons/Work";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import authenticationUtils from "../../common/security/token";
import useStyles from "./styles";

export default function Profile() {
  const history = useHistory();
  const classes = useStyles();
  const user = authenticationUtils.getUserActive();

  const handleLogout = () => {
    authenticationUtils.removeToken();
    history.push("/");
  }

  return (
    <div className={classes.interMenu}>
      <h3 className={classes.menuTitle}>
        {user?.entity.name.split(" ", 2).join(" ")}
      </h3>
      <h5 className={classes.menuSubTitle}> </h5>
      <NavLink to="/perfil">
        <AccountCircleIcon />
        Perfil
      </NavLink>
      <NavLink to="/curriculo">
        <DescriptionIcon />
        Currículo
      </NavLink>
      <NavLink to="/meus-processos">
        <WorkIcon />
        Meus Processos
      </NavLink>
      <NavLink to="/trocar-senha">
        <VpnKeyIcon />
        Trocar Senha
      </NavLink>
      <Button onClick={handleLogout}>
        <ExitToAppIcon />
        Sair
      </Button>
    </div>
  );
}
