import faqTitle from "../../assets/images/faqTitle.png";
import ImgEncaminhamento from "../../assets/images/b4.jpeg";
import ImgInteracao from "../../assets/images/b5.jpeg";
import ImgDocumentacao from "../../assets/images/sb2.jpeg";
import ImgSuporte from "../../assets/images/sb.jpeg";
import ImgParceria from "../../assets/images/b3.jpeg";

import TextPictureContent from "../../components/TextPictureContent/TextPictureContent";

function Benefits() {
  const benefits = [
    {
      title: "Encaminhamento",
      image: ImgEncaminhamento,
      description: "Encaminhamento rápido das vagas de estágios para seus contatos!",
    },
    {
      title: "Documentação",
      image: ImgDocumentacao,
      description: "Preparação de documentos e gerenciamento das contratações!",
    },
    {
      title: "Interação Ágil",
      image: ImgInteracao,
      description: "Interação ágil entre empresa, estudante e universidade.",
    },
    {
      title: "Suporte",
      image: ImgSuporte,
      description: "Suporte aos orientadores e estagiários atendendo a Lei 11.788/2008!",
    },
    {
      title: "Parcerias",
      image: ImgParceria,
      description: "Parcerias para projetos e ações do ITAI.",
    },
  ];

  return (
    <TextPictureContent title="Benefícios em Ser Nosso Parceiro" image={faqTitle} elements={benefits} />
  );
}

export default Benefits;
