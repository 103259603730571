/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import validate from "validate.js";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Collapse,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import LogoITAI from "../../assets/logos/logoHorizontalItai.svg";
import useStyles from "./LoginStyle";
import { authUser } from "../../common/api/users/user";
import authenticationUtils from "../../common/security/token";
import PasswordTextField from "../../components/InputsMasks/Password/PasswordTextField";

const schema = {
  cpf: {
    presence: { allowEmpty: false, message: "obrigatório" },
    length: {
      is: 11,
      message: "deve conter 11 dígitos",
    },
  },
  password: {
    presence: { allowEmpty: false, message: "obrigatório" },
    length: {
      maximum: 128,
      minimum: 5,
    },
  },
};

export default function Login() {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((fState) => ({
      ...fState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((fState) => ({
      ...fState,
      values: {
        ...fState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...fState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    const formStateValues = {
      username: formState.values.cpf,
      password: formState.values.password,
    };
    authUser(formStateValues)
      .then((response) => {
        authenticationUtils.setToken(response.data.token);
        history.push("/");
      })
      .catch((err) => new Error(setOpen(true), err));
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={classes.wrapper}>
      <NavLink to="/">
        <img src={LogoITAI} alt="logo Itai" />
      </NavLink>
      <Typography component="h1" variant="h5">
        PORTAL DE ESTÁGIOS
      </Typography>
      <Collapse in={open} className={classes.alert}>
        <Alert severity="warning">
          <AlertTitle>Usuário não cadastrado</AlertTitle>
          Verifique se o e-mail e senha estão corretos
        </Alert>
      </Collapse>
      <form className={classes.form} onSubmit={handleLogin}>
        <Typography component="h2" variant="h6">
          Faça seu login
        </Typography>
        <TextField
          variant="outlined"
          label="CPF"
          name="cpf"
          autoComplete="cpf"
          required
          fullWidth
          autoFocus
          className={classes.textField}
          error={hasError("cpf")}
          helperText={hasError("cpf") ? formState.errors.cpf[0] : null}
          onChange={handleChange}
          value={formState.values.cpf || ""}
        />
        <PasswordTextField
          error={hasError("password")}
          helperText={
            hasError("password") ? formState.errors.password[0] : null
          }
          onChange={handleChange}
          value={formState.values.password || ""}
        />
        <div className={classes.forgotpasssword}>
          <NavLink to="/recuperar-senha">Esqueceu sua senha?</NavLink> 
        </div>
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Permanecer conectado"
          className={classes.checkbox}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.loginButton}
        >
          Entrar
        </Button>
      </form>
      <div className={classes.signUpBox}>
        Ainda não tem cadastro?
        <br />
        <NavLink to="/cadastro">cadastre-se</NavLink>
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
