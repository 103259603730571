const stage = [
  {
    id: 1,
    name: "Aberto",
    value: "OPEN",
  },
  {
    id: 2,
    name: "Análise",
    value: "ANALYSIS",
  },
  {
    id: 3,
    name: "Seleção",
    value: "SELECTION",
  },
  {
    id: 4,
    name: "Finalizado",
    value: "FINISHED",
  },
];

export default stage;
