import authenticationUtils from "../../security/token";
import api from "./api";

const createHeaders = () => {
  const token = authenticationUtils.getToken();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (token !== undefined && token !== "") {
    return {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return headers;
};

const configHeaders = (params) => ({
  headers: createHeaders(),
  params,
});

export const get = (path, params) => api.get(`${path}`, configHeaders(params));

export const post = (path, params) =>
  api.post(`${path}`, params, configHeaders());

export const put = (path, params) =>
  api.put(`${path}`, params, configHeaders());

export const patch = (path, params) =>
  api.patch(`${path}`, params, configHeaders());

export const remove = (path) => api.delete(`${path}`, configHeaders());

export const handleRequestError = (status) => {
  if (status === 401 || status === 429 || status === 500) {
    return true;
  }
  return false;
};
