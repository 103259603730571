import { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { Typography, TextField, Button, Grid, CircularProgress } from "@material-ui/core";
import { MailOutlineOutlined, Phone } from "@material-ui/icons";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";

import contatoTitle from "../../assets/images/contatoTitle.png";

import styles from "./styles";
import { sendCompanyContactForm } from "../../common/api/contact/contact";
import AppContext from "../../AppContext";

export default function ContactBusiness() {
  const classes = styles();
  const { showAlert } = useContext(AppContext);
  const { formState: { errors, isSubmitting }, handleSubmit, register, control, reset } = useForm();

  const submitForm = async (data) => {
    await sendCompanyContactForm(data)
      .then(() => {
        showAlert("success", "Cadastro realizado com sucesso!");
        reset();
      })
      .catch(() => {
        showAlert("error", "Erro ao enviar o formulário!");
      });
  };

  return (
    <>
      <Header />
      <HeaderTitle
        title="Divulgue suas vagas"
        subtitle="Gostou dos nossos serviços, entre em contato agora mesmo com o Programa Portal de Estágios e divulgue sua vaga."
        image={contatoTitle}
      />
      <section className={classes.container}>
        <section className={classes.boxContacts}>
          <section className={classes.contactTitle}>
            <h4>Fale Conosco</h4>
          </section>
          <div className={classes.boxContactsContent}>
            <div className={classes.contactItems}>
              <Phone fontSize="large" color="#002F49" />
              <p>Telefone e WhatsApp: 45 3576-7113</p>
            </div>
            <div className={classes.contactItems}>
              <MailOutlineOutlined fontSize="large" />
              <p>estagios@itai.org.br</p>
            </div>
          </div>
        </section>
        <section className={classes.boxSeparator}>
          <h4>Ou</h4>
        </section>
        <form className={classes.form} onSubmit={handleSubmit(submitForm)} autoComplete="off">
          <Typography variant="h2" component="h2" className={classes.title}>
            Faça seu cadastro prévio:
          </Typography>
          <Grid container>
            <Grid container className={classes.gridContainer}>
              <Grid item sm={6}>
                <TextField
                  id="contactBusiness-name-id"
                  label="Seu nome"
                  variant="outlined"
                  name="name"
                  className={classes.textField}
                  {...register("name", {
                    required: "Preencha seu nome."
                  })}
                  error={!!errors?.name}
                  helperText={errors?.name ? errors.name.message : null}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="contactBusiness-position-id"
                  label="Cargo"
                  variant="outlined"
                  name="position"
                  className={classes.textField}
                  {...register("position")}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="contactBusiness-company-id"
                  label="Empresa"
                  variant="outlined"
                  name="company"
                  className={classes.textField}
                  {...register("company", {
                    required: "Preencha sua empresa."
                  })}
                  error={!!errors?.company}
                  helperText={errors?.company ? errors.company.message : null}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <Controller
                  name="cnpj"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Preencha o CNPJ."
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputMask mask="99.999.999/9999-99" value={value} onChange={onChange}>
                      {inputProps => (
                        <TextField
                          id="contactBusiness-cnpj-id"
                          label="CNPJ"
                          variant="outlined"
                          name="cnpj"
                          className={classes.textField}
                          error={!!errors?.cnpj}
                          helperText={errors?.cnpj ? errors.cnpj.message : null}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          {...inputProps}
                        />)}
                    </InputMask>)}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.gridContainer}>
              <Grid item sm={6}>
                <TextField
                  id="contactBusiness-location-id"
                  label="Cidade/Estado"
                  variant="outlined"
                  name="location"
                  className={classes.textField}
                  {...register("location", {
                    required: "Preencha seu endereço."
                  })}
                  error={!!errors?.location}
                  helperText={errors?.location ? errors.location.message : null}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <Controller
                  name="tel"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Preencha o telefone."
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputMask mask="(99) 999999999" value={value} onChange={onChange}>
                      {inputProps =>
                        <TextField
                          id="contactBusiness-tel-id"
                          label="Telefone"
                          variant="outlined"
                          name="tel"
                          type="tel"
                          className={classes.textField}
                          error={!!errors?.tel}
                          helperText={errors?.tel ? errors.tel.message : null}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          {...inputProps}
                        />
                      }
                    </InputMask>)}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="contactBusiness-email-id"
                  label="E-mail"
                  variant="outlined"
                  name="email"
                  type="email"
                  className={classes.textField}
                  {...register("email", {
                    required: "Preencha seu email.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9._%-]+\.[A-Z]{2,}$/i,
                      message: "Insira um email válido."
                    }
                  })}
                  error={!!errors?.email}
                  helperText={errors?.email ? errors.email.message : null}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="contactBusiness-response-id"
                  label="Como ficou sabendo do Portal de Estágios - ITAI?"
                  variant="outlined"
                  name="response"
                  className={classes.textField}
                  {...register("response")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.gridContainer}>
            <Grid item sm={12}>
              <TextField
                id="contactBusiness-message-id"
                label="Sua mensagem"
                variant="outlined"
                multiline
                rows="6"
                fullWidth
                name="message"
                className={classes.textFieldMultiline}
                {...register("message")}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.buttonContainner}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                type="submit"
                className={classes.button}
                disabled={isSubmitting}
              >
                Enviar
                {isSubmitting && <CircularProgress size={20} />}
              </Button>
            </Grid>
          </Grid>
        </form>
      </section>
      <Footer />
    </>
  );
}
