import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  container: {
    padding: 30,
  },
  contact: {
    display: "flex",
    justifyContent: "space-between",
    gap: "30px",
    "@media (max-width: 850px)": {
      flexWrap: "wrap",
    }
  },
  form: {
    backgroundColor: "#EEF1F2",
    boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: theme.spacing(3),
    width: "480px",
    "@media (max-width: 850px)": {
      width: "100%",
    }
  },
  title: {
    color: "#263238", 
  },
  textField: {
    backgroundColor: "#FFF",
  },
  button: {
    marginLeft: theme.spacing(12),
    marginRight: theme.spacing(12),
    minWidth: "fit-content",
  },
  map: {
    height: "550px",
    width: "100%",
  },
  footerContacts: {
    width: "100%",
    border: "1px solid #EEF1F2",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    marginTop: "35px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      border: "none",
    }
  },
  footerContactsContent: {
    flex: 1,
    padding: "25px 45px",
    borderRight: "2px solid #EEF1F2",
    "& h4": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      marginBottom: "5px",
    },
    "@media (max-width: 800px)": {
      border: "none",
    }
  },
  footerContactsContentLast: {
    flex: 1,
    padding: "25px 45px",
    "& h4": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      marginBottom: "5px",
    },
  },
  helperText: {
    fontSize: "1rem",
  },
}));

export default styles;
