import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 30,
    display: "flex",
    flex: 1,
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    }
  },
  oportunitiesContainer: {
    width: "100%",
  },
  filterContainer: {
    width: "400px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    }
  },
  containerForm: {
    padding: "0 100px",
  },

  input: {
    width: 300,
    marginTop: -30,
    marginBottom: 30,
    zIndex: 1000,
    background: "white",
    "&:nth-child(1), .MuiOutlinedInput-root": {
      borderRadius: "6px 0px 0px 6px",
    },
    "&:nth-child(2) .MuiOutlinedInput-root ": {
      borderRadius: "0",
    },
  },
  search: {
    width: "100%",
    maxWidth: "150px",
    background: "#002F49",
    borderRadius: "0px 6px 6px 0px",
  },
  link: {
    width: "304px",
    height: "52px",
    background: "#F7C601",
    borderRadius: "6px",
  },
  internshipLenght: {
    borderBottom: "1px solid #CCC",
    padding: "15px",
    marginBottom: "30px",
    color: "#263238"
  },
  card: {
    border: "1px solid #CCC",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    maxWidth: "350px",
    minWidth: "300px",
    height: "fit-content",
    marginRight: "30px",

    "& img": {
      maxHeight: "25px",
      maxWidth: "25px",
      marginRight: "10px",
    },
    "& h4": {
      fontSize: "25px",
      fontWeight: "500",
      fontFamily: "Rubik",
    },
  },
  cardTitle: {
    borderBottom: "1px solid #CCC",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",

    "& button": {
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",

    "& h3": {
      fontWeight: "500",
    },
  },
  checkbox: {
    display: "flex",
    flexDirection: "column",
  },
  formControlLabel: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(20),
  },

  salaryText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(28),
  },
  slider: {
    width: "90%",
  },
  details: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#002F49",
    flex: 1,
    padding: "8px 20px",
    borderRadius: "6px",
    color: "white",
    fontSize: "15px",
    textTransform: "none",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: theme.spacing(1),

    "&:hover": {
      backgroundColor: "#000D27",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: 500,
  },
}));

export default useStyles;
