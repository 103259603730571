import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "top",
    gap: '20px',
    width: "100vw",
    height: "100vh",
    padding: "20px 20px 0 20px",
    color: "#002F49",
    paddingTop: '50px',

    "& h1": {
      color: '#002F49',
      fontWeight: "bolder",
      letterSpacing: "0.5px",
      marginBottom: "10px",
    },
    "& a": {
      display: "flex",
      justifyContent: "center",
    },
    "& img": {
      width: "70%",
    },
    "& .MuiAlert-root": {
      padding: "6px",
    },
  },
  alert: {
    width: "100%",
    color: "#002F49",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "fit-content",
    marginTop: "8px",
    color: "#002F49",
    "& h2": {
      color: "#002F49",
    }
  },
  button: {
    height: "40px",
    backgroundColor: "#002F49",
    color: "#fff",
    borderRadius: "6px",
    marginTop: "15px",
  },
  textField: {
    marginTop: "15px",
  },
  returnLoginBox: {
    color: "#002F49",
    cursor: "pointer",
    letterSpacing: "0.8px",
    fontWeight: "400",
    padding: "18px 10px",
    textAlign: "center",
    borderTop: "1px solid #F7C601",
    borderBottom: "1px solid #F7C601",
    margin: "40px 0",
  }
}));

export default useStyles;
