import React, { useContext, useState } from "react";

import { Button, TextField, Typography } from "@material-ui/core";
import useStyles from "../styles/ModalsContentStyle";
import api from "../../../common/api/config/api";
import AppContext from "../../../AppContext";
import modalContext from "../context/ModalContext";

export default function ForgotPassword() {
  const { showAlert } = useContext(AppContext);
  const { handleModalClose } = useContext(modalContext);

  const [value, setValue] = useState({
    email: "",
  });
  const classes = useStyles();

  const handleChange = (e) => {
    setValue({ email: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    api.post("/password-recovery", value).then(() => {
      showAlert("success", "Email Enviado!");
      handleModalClose();
    });
  };

  return (
    <div className={classes.wrapper}>
      <Typography component="h1" variant="h5">
        Esqueceu sua senha?
      </Typography>
      <form className={classes.form} noValidate onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="E-mail"
          name="email"
          autoComplete="email"
          autoFocus
          className={classes.textField}
          value={value.password}
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.loginButton}
        >
          Recuperar senha
        </Button>
      </form>
    </div>
  );
}
