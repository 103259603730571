import { Card, CardActions, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

import Company from '../../assets/icons/company.png';
import Student from '../../assets/icons/student.png';
import University from '../../assets/icons/university.png';
import faqTitle from '../../assets/images/faqTitle.png';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';
import Modal from '../../components/Modal/Modal';
import styles from './styles';

function About() {
    const classes = styles();
    const [modalOpen, setModalOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const history = useHistory();

    const handleModalOpen = () => {
      if(isMobile){
        history.push("/cadastro")
      }else{
        setModalOpen(true);
      }
    };
  
    const handleModalClose = () => {
      setModalOpen(false);
    };

    return (
        <>
            <Header />
            <HeaderTitle title="Conheça o Portal" image={faqTitle} />
            <section className={classes.container}>
                <section className={classes.container1}>
                    <Card className={classes.card}>
                        <Typography variant="h5" color="textPrimary" component="h4">
                            O Programa
                        </Typography>
                        <CardActions disableSpacing>
                            <Typography color="textSecondary" component="p">
                                O Programa Portal de Estágios é uma iniciativa do ITAI –
                                Instituto de Tecnologia Aplicada e Inovação que possui mais de 20 anos de atuação
                                no desenvolvimento de ações, projetos e pesquisas, sempre integrando o mundo
                                acadêmico com as demandas do mercado.
                            </Typography>
                        </CardActions>
                    </Card>
                    <Card className={classes.card}>
                        <Typography variant="h5" color="textPrimary" component="h4">
                            O que fazemos?
                        </Typography>
                        <CardActions disableSpacing>
                            <Typography color="textSecondary" component="p">
                                O Programa Portal de Estágios realiza a intermediação de estágios de ensino técnico
                                e superior por meio de processos efetivos que proporcione legalmente a interação entre
                                empresas, instituições de ensino e estudantes.
                            </Typography>
                        </CardActions>
                    </Card>
                    <Card className={classes.card}>
                        <Typography variant="h5" color="textPrimary" component="h4">
                            O que apoiamos?
                        </Typography>
                        <CardActions disableSpacing>
                            <Typography color="textSecondary" component="p">
                                Apoia de forma ativa as vivências laborais para que os estudantes possam aplicar na
                                prática, no dia-a-dia do mercado de trabalho, todos conhecimentos escolares e
                                universitários resultando em um aprendizado mais completo e significativo na carreira
                                profissional que se inicia.
                            </Typography>
                        </CardActions>
                    </Card>
                </section>
            </section>
            <section>
                <Typography variant="body2" component="p" className={classes.separator}>
                    Venha fazer parte dessa rede de parcerias
                </Typography>
            </section>
            <section className={classes.container}>
                <section className={classes.container_squares}>
                    <Card className={classes.square} onClick={handleModalOpen}>
                        <img src={Student} alt="Student" />
                        <Typography variant="h5" color="textPrimary" component="h4">
                            Estudante
                        </Typography>
                        <CardActions disableSpacing>
                            <Typography color="textSecondary" component="p">
                                Cadastre já seu currículo e encontre vagas de estágio!
                            </Typography>
                        </CardActions>
                    </Card>
                  <Card className={classes.square}>
                    <NavLink to="/contato-empresa">
                        <img src={Company} alt="Company" />
                        <Typography variant="h5" color="textPrimary" component="h4">
                            Empresa
                        </Typography>
                        <CardActions disableSpacing>
                            <Typography color="textSecondary" component="p">
                                Divulgue suas vagas  e encontre os melhores candidatos!
                            </Typography>
                        </CardActions>
                    </NavLink>
                  </Card>
                  <Card className={classes.square}>
                    <NavLink to="/contato-ie">
                        <img src={University} alt="University" />
                        <Typography variant="h5" color="textPrimary" component="h4">
                            Escolas e Universidades
                        </Typography>
                        <CardActions disableSpacing>
                            <Typography color="textSecondary" component="p">
                                Venham ser nossas parceiras!
                            </Typography>
                        </CardActions>
                    </NavLink>
                  </Card>
                </section>
            </section>
            <Footer />
            <Modal opened={modalOpen} handleModalClose={handleModalClose} activatedStep={2} />
        </>
    );
}

export default About;
