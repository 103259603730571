import { post } from "../config/api_methods";

export const sendContactForm = (params) => {
  const response = post(`/contact/commom`, params);
  return response;
};

export const sendIEContactForm = (params) => {
  const response = post(`/contact/educational-institution`, params);
  return response;
};

export const sendCompanyContactForm = (params) => {
  const response = post(`/contact/company`, params);
  return response;
};
