import { Button } from "@material-ui/core";
import { useContext, useState } from "react";
import { useLocation, useHistory } from "react-router";
import InputProfile from "../../components/InputProfile/InputProfile";
import AppContext from "../../AppContext";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import useStyles from "./styles";
import api from "../../common/api/config/api";

export default function ResetPassword() {
  const queryParams = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const [values, setValues] = useState({});
  const { showAlert } = useContext(AppContext);

  const classes = useStyles();

  const handleValues = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      token: queryParams.get("token"),
      password: values.newPassword,
    };

    if (values.newPassword !== values.confirmPassword) {
      showAlert("error", "Suas senhas não estão iguais, tente novamente!");
    } else {
      api
        .post("reset-password", data)
        .then(() => showAlert("success", "Sua senha foi alterada com sucesso!"))
        .catch(() =>
          showAlert("error", "Suas senhas não se coincidem, tente novamente!")
        );
    }
    history.push("/");
  };

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.body}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <h2>Alterar Senha</h2>
          <InputProfile
            onChange={handleValues}
            name="newPassword"
            label="Nova Senha"
            value={values.newPassword}
            type="password"
            className={classes.inputForm}
          />
          <InputProfile
            onChange={handleValues}
            name="confirmPassword"
            label="Confirmar Nova Senha"
            value={values.confirmPassword}
            type="password"
            className={classes.inputForm}
          />
          <div className={classes.buttonView}>
            <Button className={classes.saveButton} type="submit">
              Salvar
            </Button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
}
