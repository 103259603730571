import { Grid, Typography } from "@material-ui/core";
import useStyle from "./styles";


import logoIcon from "../../assets/icons/logoItaiIcon.svg";
import SignUpButton from "../SignUpButton/SignUpButton";
import SocialNetworkIcons from "./SocialNetworkIcons";

import authenticationUtils from "../../common/security/token";

export default function Footer() {
  const classes = useStyle();

  return (
    <footer className={classes.footer}>
      <Grid
        container
        spacing={3}
        justifyContent="space-around"
        className={classes.primaryGrid}
      >
        <Grid item lg={3} md={5} sm={12} xs={12}>
          <div className={classes.footerTitle}>
            <img src={logoIcon} alt="Itai" />
            <Typography variant="h4">Portal de Estágios</Typography>
          </div>

          {!authenticationUtils.isAuthenticated() && <SignUpButton /> }
          <SocialNetworkIcons />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Typography variant="h5" align="left">Perguntas Frequentes</Typography>
          <ul>
            <li>Segurança e Privacidade</li>
            <li>Termos de Uso</li>
            <li>Contato com o suporte</li>
            <li>Ajuda</li>
            <li>LGPD</li>
          </ul>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Typography variant="h5">Oportunidades</Typography>
          <ul>
            <li>PTI</li>
            <li>ITAI</li>
            <li>UniAmérica</li>
            <li>Lasse</li>
            <li>GDE</li>
          </ul>
        </Grid>
      </Grid>
      <Typography variant="body2" component="p" className={classes.copyRight}>
        Portal de Estágios do <strong>ITAI</strong> com apoio da{" "}
        <strong>UniAmérica</strong>
      </Typography>
    </footer>
  );
}
