import authenticationUtils from "../../common/security/token";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ChangePasswordForm from "./sections/ChangePasswordForm";
import useStyles from "./styles";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";

export default function ChangePassword() {
  const classes = useStyles();
  const user = authenticationUtils.getUserActive();

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.body}>
        <ProfileMenu />
        <ChangePasswordForm id={user.entity.id} />
      </div>
      <Footer />
    </div>
  );
}
