export const oportunityStage = {
  CREATED: "Criado",
  OPEN: "Aberto",
  ANALYSIS: "Em Análise de Currículos",
  SELECTION: "Em Seleção de Candidatos",
  FINISH: "Finalizado",
};

export const oportunityStageColor = {
  CREATED: "blue",
  OPEN: "green",
  ANALYSIS: "gold",
  SELECTION: "orange",
  FINISH: "red",
};
