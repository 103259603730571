import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  container: {
    padding: 30,
  },
  resume: {
    maxWidth: "1200px",
    border: "1px solid #CCC",
    borderRadius: "8px",
    padding: "50px",
    margin: "0 auto",
    lineHeight: "3.5rem",
    textAlign: "justify",
    fontFamily: "Roboto",
  },
  container1: {
    display: "flex",
    gap: "2rem",
    justifyItems: "center",
    paddingLeft: "20px",

    "@media (max-width: 850px)": {
      flexDirection: "column",
    }
  },
  card: {
    maxWidth: "400px",
    minHeight: "300px",
    borderLeft: "10px solid #2B708B",
    margin: "20px auto",
    borderRadius: "6px",
    padding: "2rem",
    "& p": {
      lineHeight: "2rem",
      fontSize: "1.2rem"
    },

    "@media (max-width: 680px)": {
      minHeight: "200px",
    }
  },
  separator: {
    backgroundColor: "#19435B",
    color: "#fff",
    fontSize: "2rem",
    textAlign: "center",
    lineHeight: "2.8rem",
    width: "100%",
    padding: "2rem"
  },
  square: {
    width: "250px",
    minHeight: "250px",
    margin: "20px auto",
    borderRadius: "6px",
    backgroundColor: "#EEF1F2",
    padding: "2rem",
    cursor: "pointer",
    "& p": {
      fontSize: "1.2rem"
    },
    "& img": {
      maxWidth: "50px"
    },
    "& h4": {
      justifyContent: "center",
    },

    "@media (max-width: 680px)": {
      minHeight: "200px",
    }
  },
  container_squares: {
    display: "flex",
    gap: "2rem",
    fontSize: "1rem",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",

    "@media (max-width: 680px)": {
      flexDirection: "column",
    }
  },
  header: {
    backgroundColor: "#EEF1F2",
    color: "black",
    fontWeight: "600",
    textAlign: "left",
    padding: "20px 30px",
  },
  content: {
    padding: "20px 30px",
    lineHeight: "2rem",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default styles;
