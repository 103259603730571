import { Button } from "@material-ui/core";
import { useContext, useState } from "react";
import { updatePassword } from "../../../common/api/users/userSettings";
import useStyles from "../styles";
import InputProfile from "../../../components/InputProfile/InputProfile";
import AppContext from "../../../AppContext";

import AuthenticationUtils from "../../../common/security/token";

export default function ChangePasswordForm() {
  const id = AuthenticationUtils.getUserId();
  const classes = useStyles();
  const [values, setValues] = useState({});
  const { showAlert } = useContext(AppContext);

  const handleValues = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      id,
      newPassword: values.newPassword,
      oldPassword: values.oldPassword,
    };

    if (values.newPassword !== values.confirmPassword) {
      showAlert("error", "Suas senhas não estão iguais, tente novamente!");
    } else {
      updatePassword(data)
        .then(() => showAlert("success", "Sua senha foi alterada com sucesso!"))
        .catch(() =>
          showAlert("error", "Suas senhas não se coincidem, tente novamente!")
        );
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <h2>Alterar Senha</h2>
      <InputProfile
        name="oldPassword"
        label="Senha Atual"
        value={values.oldPassword}
        onChange={handleValues}
        type="password"
        className={classes.inputForm}
      />
      <InputProfile
        onChange={handleValues}
        name="newPassword"
        label="Nova Senha"
        value={values.newPassword}
        type="password"
        className={classes.inputForm}
      />
      <InputProfile
        onChange={handleValues}
        name="confirmPassword"
        label="Confirmar Nova Senha"
        value={values.confirmPassword}
        type="password"
        className={classes.inputForm}
      />
      <div className={classes.buttonView}>
        <Button className={classes.saveButton} type="submit">
          Salvar
        </Button>
      </div>
    </form>
  );
}
