import { Box } from "@material-ui/core";
import DropDownButton from "../../DropDownButton/DropDownButton";
import useStyles from "../styles/Headerstyle";
import  authenticationUtils from "../../../common/security/token"


export default function NavLinks() {
  const classes = useStyles();

  return (
    <Box className={classes.navLink}>
      <DropDownButton
        buttonLabel="O Portal"
        submenuOptions={[
          {
            route: "/sobre",
            name: "Sobre",
          },
          {
            route: "/contato",
            name: "Contato",
          },
        ]}
      />
      <DropDownButton
        buttonLabel="Estudante"
        submenuOptions={[
          {
            route: "/oportunidades",
            name: "Vagas de Estágio",
          },
          {
            name: "Cadastre-se",
            signUpModal: true,
            show: authenticationUtils.isAuthenticated()
          },
        ]}
      />
      <DropDownButton
        buttonLabel="Empresa"
        submenuOptions={[
          {
            route: "/servicos",
            name: "Serviços",
          },
          {
            route: "/legislacao",
            name: "Legislação",
          },
          {
            route: "/contato-empresa",
            name: "Divulgue suas Vagas",
          },
        ]}
      />
      <DropDownButton
        buttonLabel="Instituição de Ensino"
        submenuOptions={[
          {
            route: "/beneficios",
            name: "Benefícios",
          },
          {
            route: "/contato-ie",
            name: "Cadastre-se",
          },
        ]}
      />
    </Box>
  );
}
