import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  home: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  grid: {
    backgroundColor: "#FFFFFF",
    width: "90%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    "& h2": {
      textAlign: "left",
      marginBottom: theme.spacing(4),
    },
  },
  cards: {
    width: "100%",
  },
  content: {
    display: "flex",
    gap: "20px",
  },
  cookieButton: {
    "&:hover": {
      filter: "brightness(0.9)",
    },
  },
}));

export default useStyles;
