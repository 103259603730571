import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import useStyle from "../styles";

const CpfTextField = ({ helperText, error, value, handleChange, ...props }) => {
  const classes = useStyle();

  return (
    <InputMask
      mask="999.999.999-99"
      onChange={handleChange}
      value={value}
    >
      {() => <TextField
        className={classes.textField}
        name="cpf"
        label="CPF"
        fullWidth
        variant="outlined"
        required
        error={error}
        helperText={helperText}
        {...props}
      />}
    </InputMask>
  );
};

CpfTextField.propTypes = {
  helperText: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

CpfTextField.defaultProps = {
  helperText: "",
  error: false,
};

export default CpfTextField;
