/* eslint-disable react/jsx-no-duplicate-props */
import 'moment/locale/pt-br';

import MomentUtils from '@date-io/moment';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useContext, useEffect, useState } from 'react';

import classesPeriodOptions from '../../../assets/data/classesPeriodOptions';
import graduationScheduleOptions from '../../../assets/data/graduationScheduleOptions';
import situationOptions from '../../../assets/data/situationOptions';
import typeOptions from '../../../assets/data/typeOptions';
import { addCourse, getCourses } from '../../../common/api/courses/courses';
import { addCourseToInstitution } from '../../../common/api/educationalInstitution/courses';
import { addStudentEnrollment } from '../../../common/api/student/enrollments';
import InputProfile from '../../../components/InputProfile/InputProfile';
import SelectResume from '../../../components/SelectResume/SelectResume';
import enrollmentContext from '../context/EnrollmentContext';
import useStyles from '../styles';

const filter = createFilterOptions();
export default function NewCourseForm() {
  const classes = useStyles();
  const [coursesOptions, setCoursesOptions] = useState([])
  const { 
    settings, 
    enrollment, 
    institutionsOptions, 
    handleValues, 
    setEnrollment 
  } = useContext(enrollmentContext);

  const thisSettings = async () => {
    const { data } = await getCourses();
    setCoursesOptions(data.data.content);
  };

  const AddEnrollment = async () => {
    const newCourse = coursesOptions.filter(course => course.name === enrollment.courseInfo?.name);
    if (!newCourse.length > 0) {
      const newCourseInfo = await addCourse({name: enrollment.courseInfo.inputValue});
      const { data } = await addCourseToInstitution(enrollment.institution.id, {
        classesPeriod: enrollment.classesPeriod,
        courseInfo: newCourseInfo.data.data,
        duration: enrollment.duration,
        graduationSchedule: enrollment.graduationSchedule,
        educationalInstitution: enrollment.educationalInstitution,
        type: enrollment.type,
      })
      await addStudentEnrollment({
        course: data.data,
        situation: enrollment.situation,
        graduationPeriod: enrollment.graduationPeriod,
        endDate: enrollment.endDate,
        startDate: enrollment.startDate,
      });
    } else {
      const { data } = await addCourseToInstitution(enrollment.institution.id, {
        classesPeriod: enrollment.classesPeriod,
        courseInfo: enrollment.courseInfo,
        duration: enrollment.duration,
        graduationSchedule: enrollment.graduationSchedule,
        educationalInstitution: enrollment.educationalInstitution,
        type: enrollment.type,
      })
      await addStudentEnrollment({
        course: data.data,
        situation: enrollment.situation,
        graduationPeriod: enrollment.graduationPeriod,
        endDate: enrollment.endDate,
        startDate: enrollment.startDate,
      });
    }
    settings();
  };

  useEffect(() => {
    thisSettings();
  }, []);

  return (
    <div className={classes.enrollmentsInputs}>
      <SelectResume
        value={enrollment.institution}
        id="enrollment-institution-select"
        name="institution"
        label="Instituição Educacional"
        options={institutionsOptions}
        onChange={handleValues}
        labelOption="companyName"
      />
      <Autocomplete
        value={enrollment.courseInfo}
        onChange={(event, newValue) => {
          if (typeof newValue === 'object') {
            setEnrollment({
              ...enrollment,
              courseInfo: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            setEnrollment({
              ...enrollment,
              courseInfo: newValue.inputValue,
            });
          }
        }}
       className={classes.coursesAutocomplete}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Adicionar "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="enrollment-course-select-2"
        options={coursesOptions}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        renderOption={(option) => option.name}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Curso" variant="outlined" style={{ width: "100%" }}/>
        )}
      />
      <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
        <KeyboardDatePicker
          inputVariant="outlined"
          variant="inline"
          openTo="month"
          label="Data Inicial"
          className={classes.datePicker}
          views={["year", "month"]}
          value={enrollment.startDate}
          onChange={(value) =>
            setEnrollment({ ...enrollment, startDate: value })
          }
        />
      </MuiPickersUtilsProvider>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="br">
        <KeyboardDatePicker
          inputVariant="outlined"
          variant="inline"
          className={classes.datePicker}
          openTo="month"
          label="Data Final"
          views={["year", "month"]}
          value={enrollment.endDate}
          onChange={(value) =>
            setEnrollment({ ...enrollment, endDate: value })
          }
        />
      </MuiPickersUtilsProvider>
      <SelectResume
        value={enrollment.type}
        id="enrollment-type-select"
        name="type"
        label="Nível"
        options={typeOptions}
        onChange={handleValues}
      />
      <SelectResume
        value={enrollment.graduationSchedule}
        id="enrollment-graduationSchedule-select"
        name="graduationSchedule"
        label="Periodo de Graduação"
        options={graduationScheduleOptions}
        onChange={handleValues}
      />
      <SelectResume
        value={enrollment.classesPeriod}
        id="enrollment-classesPeriod-select"
        name="classesPeriod"
        label="Turno"
        options={classesPeriodOptions}
        onChange={handleValues}
      />
      <InputProfile
        onChange={handleValues}
        name="duration"
        label="Duração do curso"
        value={enrollment.duration}
        widthSet="30%"
        inputProps={{
          step: "0.5",
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">anos</InputAdornment>
        }}
        error={enrollment.duration % 0.5 !== 0}
        type="number"
        helperText="Insira apenas números inteiros ou com decimal 5. Ex: 2,5"
      />
      <SelectResume
        value={enrollment.situation}
        id="enrollment-situation-select"
        name="situation"
        label="Situação"
        options={situationOptions}
        onChange={handleValues}
      />
      {enrollment.situation === situationOptions[0].value ? (
        <InputProfile
          value={enrollment.graduationPeriod}
          name="graduationPeriod"
          label="Período atual"
          required={false}
          onChange={handleValues}
          widthSet="30%"
        />
      ) : null}
      <Button
        className={classes.addButton}
        type="button"
        onClick={AddEnrollment}
      >
        +
      </Button>
    </div>
  );
}
