import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  grid: {
    backgroundColor: "#FFFFFF",
    width: "90%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: "center",
    "& h2": {
      display: "inline",
      textAlign: "center",
    },
    "@media (max-width: 680px)": {
      width: "80vw",
    }
  },
  box: {
    width: "250px",
    height: "150px",
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      maxWidth: "80%",
      textAlign: "center",
    },
    "@media (max-width: 680px)": {
      width: "30vw",
    }
  },
}));

export default useStyles;
