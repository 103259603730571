import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    padding: 0,
    alignItems: "center",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    padding: "20px 20px 0 20px",

    "& h1": {
      color: "#002F49",
    },
    "& .MuiAlert-root": {
      padding: "6px",
    },
    "& .MuiTypography-body1": {
      fontSize: "12px",
      lineHeight: "12px",
    },

    "& .MuiAlert-message": {
      fontSize: "10px",
      fontWeight: "400",
      lineHeight: "12px",
    },
  },
  form: {
    width: "100%",
    marginTop: "8px",
  },
  submit: {
    margin: "24px 0 16px",
    backgroundColor: "#198a86",
  },
  alert: {
    width: "100%",
    marginTop: "10px",
  },
  loginButton: {
    height: "40px",
    background: "#002F49",
    color: "white",
    borderRadius: "6px",
    fontSize: "16px",
    padding: "12px 25px",
    marginTop: "15px",

    "&:hover": {
      background: "#001E38",
    },
  },
  checkbox: {
    "& span": {
      fontSize: "13px",
      lineHeight: "16px",
    },
  },
  privacyPolice: {
    padding: "10px 0 0 0px",
  },
  textField: {
    marginTop: "15px",

    "& input": {
      fontSize: "14px",
    },

    "& label": {
      fontSize: "14px",
      color: "#002F49",
    },
  },
  forgotpasssword: {
    fontSize: "12px",
    textTransform: "none",
    justifyContent: "flex-end",
    paddignTop: "0",
    width: "100%",

    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
  },
}));

export default useStyles;
