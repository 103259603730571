import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import AppContext from "./AppContext";
import Routes from "./routes";
import theme from "./theme";

import "./global.css";

function App() {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({
      ...alert,
      open: false,
    });
  };

  const showAlert = (severity, message) => {
    setAlert({
      open: true,
      severity,
      message,
    });
  };

  return (
    <AppContext.Provider value={{ showAlert }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
        <Snackbar
          translate="no"
          autoHideDuration={4000}
          open={alert.open}
          onClose={handleAlertClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MuiAlert
            severity={alert.severity}
            onClose={handleAlertClose}
            variant="filled"
          >
            {alert.message}
          </MuiAlert>
        </Snackbar>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
