import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  button: {
    color: "#002F49",
    marginRight: "20px",
    padding: "6px 15px 6px 0",
    minWidth: "fit-content",
    fontWeight: "500",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
    textTransform: "none",
    lineHeight: "23px",
    display: "flex",
    gap: "6px",
    alignItems: "center",

    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#fefefe",
      color: "#263238",
    },
  },

  dropdown: {
    display: "flex",
    alignItems: "left",
    gap: "10px",
    color: "#002F49",

    "& a": {
      textTransform: "none",
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "300",
      padding: "0",
      width: "100%",
    },

    "& a:hover": {
      fontWeight: "400",
    },
  },
}));

export default useStyles;
